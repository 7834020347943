var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "name"
      ? _c(
          "div",
          { staticClass: "name__wrapper" },
          [
            _c("ReadMore", {
              attrs: {
                text: _vm.data.row.name,
                to: _vm.canPreview ? _vm.previewLocation : null,
              },
            }),
          ],
          1
        )
      : _vm.data.column.field === "size"
      ? _c("div", [_vm._v(" " + _vm._s(_vm.data.row.readSize) + " ")])
      : _vm._e(),
    _vm.data.column.field === "rows"
      ? _c("div", { staticClass: "space-between" }, [
          _c("span", [_vm._v(_vm._s(_vm.data.row.readRows))]),
          _c(
            "div",
            { staticClass: "space-between" },
            [
              _c(
                "b-button",
                {
                  staticClass: "new-query__button",
                  attrs: { disabled: !_vm.canQuery, id: "new-query-item" },
                  on: {
                    click: function ($event) {
                      _vm.canQuery ? _vm.formatTemplateQuery(_vm.data.row) : {}
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/svgs/compose.svg") },
                  }),
                  _c("span", [_vm._v("New Query")]),
                ]
              ),
              _c(
                "span",
                { staticClass: "workspace__actions" },
                [
                  _c("DropdownActions", {
                    attrs: { id: "table-actions", items: _vm.actionItems },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }