var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.execute.loading
      ? _c("div", { staticClass: "loading__container" }, [_c("LoadingBar")], 1)
      : _vm.data.execute.error
      ? _c("div", { staticClass: "results__errors" }, [
          _c("img", {
            attrs: { src: require("../../assets/svgs/infomark.svg") },
          }),
          _c("span", { staticClass: "results__errors-text" }, [
            _vm._v(_vm._s(_vm.data.execute.error)),
          ]),
        ])
      : _c(
          "div",
          { staticClass: "table__container" },
          [
            _vm.data.table.rows.length > 0
              ? _c("ResultsTable", {
                  attrs: { data: _vm.data.table },
                  on: {
                    "update-table": function ($event) {
                      return _vm.$emit("update-table")
                    },
                  },
                })
              : _c("div", { staticClass: "no-data__container" }, [
                  _c("span", [_vm._v(" There is no data to display. ")]),
                ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }