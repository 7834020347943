import Vue from 'vue'
import Vuex from 'vuex'
import preview from './preview'
import schema from './schema'
import workspace from './workspace'
import results from './results'
import dashboard from './dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  actions: {
  },
  mutations: {
  },
  modules: {
    preview,
    schema,
    workspace,
    results,
    dashboard
  }
})
