var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    _vm._v(
      " " +
        _vm._s(_vm.data.row[_vm.headers.indexOf(_vm.data.column.field)]) +
        " "
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }