<template>
  <div id="content__wrapper" class="content__wrapper">
    <div class="preview__header">
      <span class="preview__text-information"
        >Preview latest 100 events collected</span
      >
    </div>
    <div class="table__wrapper preview__table not-sortable__table">
      <CustomTable
        type-of-table="preview"
        :data="previewData"
        :is-loading="isLoading"
        :is-sort-enabled="false"
      >
        <template #row-data="{ data }">
          <PreviewRow :data="data" />
        </template>
        <template #column-data="{ data }">
          <ResizableColumn :content="data.column.label" />
        </template>
      </CustomTable>
    </div>
    <div
      class="preview__footer"
      @click="
        $router.push({
          name: 'biskql-workspace',
        })
      "
    >
      <feather-icon
        icon="ChevronLeftIcon"
        size="16"
        class="pagination__icon"
      /><span id="back-button" class="preview__footer-text">Back</span>
    </div>
  </div>
</template>

<script>
import PreviewRow from '@/components/table-data/PreviewRow.vue'
import router from '@/router'
import store from '@/store'
import { CustomTable, ResizableColumn } from '@nodus/utilities-front'
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
} from 'vue'

export default {
  name: 'Preview',
  components: {
    PreviewRow,
    CustomTable,
    ResizableColumn,
  },
  setup() {
    const root = getCurrentInstance().proxy
    const { organizationId, tableName } = root.$route.params
    const previewData = computed(() => store.getters['preview/getData'])
    const isLoading = ref(true)

    onBeforeMount(() => {
      if (!organizationId) router.push('/organizations')
    })

    onMounted(async () => {
      await store.dispatch('preview/getPreviews', {
        organizationId,
        tableName,
      })
      isLoading.value = false
    })

    return {
      previewData,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/views/preview.scss';
@import '@/assets/scss/views/shared/dynamictable.scss';
</style>
