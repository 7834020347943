import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import { destructJSONObject } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      pageLength: 10,
      columns: [],
      headers: [],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
    },
  },
  getters: {
    getData: (state) => state.table,
    getColumnHeaders: (state) => state.table.headers,
  },
  actions: {
    async getPreviews({ commit }, { organizationId, tableName }) {
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/tables/${tableName}/preview`
      )
      const rows = destructJSONObject(response, 'dynamicData')

      commit(
        'SET_HEADERS',
        rows?.length ? destructJSONObject(response, 'header') : []
      )
      commit('SET_ROWS', rows)
      commit('SET_COLUMNS')
    },
  },
  mutations: {
    SET_COLUMNS(state) {
      const headers = []

      state.table.headers.forEach((col) => {
        headers.push({ field: col, label: col })
      })
      state.table.columns = headers
    },
    SET_HEADERS(state, headers) {
      state.table.headers = headers
    },
    SET_ROWS(state, rows) {
      state.table.rows = rows ?? []
    },
  },
}
