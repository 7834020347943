<template>
  <div>
    <div class="row-number__index table__cell">{{ source.index }}</div>
    <div 
      v-for="(cell, cellIndex) in source.data" 
      :key="cellIndex"
      :id="`cell-${cellIndex}`"
      class="table__cell"
      :class="resizedColumns.find(rc => +rc.colIndex === cellIndex) 
              ? resizedColumns.find(rc => +rc.colIndex === cellIndex).styleClass : 'text__nowrap'"
      :style="`min-width: ${resizedColumns.find(rc => +rc.colIndex === cellIndex) 
                            ? resizedColumns.find(rc => +rc.colIndex === cellIndex).width : 180}px`"
    >
      <span>{{ cell }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'results-table-row',
  props: {
    index: {
      type: Number
    },
    source: {
      type: Object,
      default: () => {}
    },
    resizedColumns: {
      type: [Array],
      default: () => []
    }
  }
}
</script>