var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.column.field === "processed"
      ? _c("span", [
          _vm._v(
            " " +
              _vm._s(
                `${_vm.data.row.processed.toLocaleString()} ${
                  _vm.data.row.processedUnit
                }`
              ) +
              " "
          ),
        ])
      : _c("span", [
          _vm._v(
            " " + _vm._s(_vm.data.formattedRow[_vm.data.column.field]) + " "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }