<script>
import ResultsTable from '@/components/table-components/ResultsTable.vue';
import {LoadingBar} from '@nodus/utilities-front';

export default {
  emits: ["update-table"],
  props: {
    data: {
      type: [Array, Object],
    },
  },
  components: {
    ResultsTable,
    LoadingBar,
  },
  setup() {},
};
</script>

<template>
  <div>
    <div v-if="data.execute.loading" class="loading__container">
      <LoadingBar />
    </div>
    <div v-else-if="data.execute.error" class="results__errors">
      <img src="../../assets/svgs/infomark.svg" />
      <span class="results__errors-text">{{ data.execute.error }}</span>
    </div>
    <div v-else class="table__container">
      <ResultsTable
        v-if="data.table.rows.length > 0"
        :data="data.table"
        @update-table="$emit('update-table')"
      />
      <div v-else class="no-data__container">
        <span> There is no data to display. </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/views/editor-panes/results.scss";
</style>
