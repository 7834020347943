<template>
  <div id="content__wrapper" class="dashboard__wrapper content__wrapper">
    <div class="dashboard__header">
      <div class="dashboard__header--left">
        <div class="form__input">
          <v-select
            id="tables-select"
            v-model="primaryFilters.selected.table"
            label="name"
            placeholder="All tables"
            :options="primaryFilters.options.tables"
            class="multiple__select"
            multiple
            :deselect-from-dropdown="true"
            :close-on-select="false"
            @input="updateApi()"
          >
            <template #selected-option="option">
              <span
                v-if="primaryFilters.selected.table[0].name === option.name"
              >
                {{ option.name }}
              </span>
              <span
                v-else-if="
                  primaryFilters.selected.table[1].name === option.name
                "
                id="more-options"
              >
                (+ {{ primaryFilters.selected.table.length - 1 }} others)
                <b-tooltip
                  target="more-options"
                  triggers="hover"
                  placement="right"
                >
                  <div
                    v-for="(item, index) in primaryFilters.selected.table"
                    :key="index"
                  >
                    <span v-if="index > 0">{{ item.name }}</span>
                  </div>
                </b-tooltip>
              </span>
            </template>
          </v-select>
        </div>
        <div class="form__input">
          <v-select
            id="size-select"
            v-model="primaryFilters.selected.unit"
            label="unit"
            placeholder="Select size"
            :options="primaryFilters.options.units"
            :clearable="false"
            :searchable="false"
            @input="updateApi()"
          />
        </div>
        <div class="form__input">
          <v-select
            id="frequency-select"
            v-model="primaryFilters.selected.period"
            label="period"
            placeholder="Select frequency"
            :options="primaryFilters.options.periodicity"
            :clearable="false"
            :searchable="false"
            @input="validateWeeklyRange"
          />
        </div>
      </div>
      <div class="dashboard__header--right">
        <span>Show data per: </span>
        <div
          class="form__input date-range__input"
          :class="{ 'date-range__invalid': !!dateRangeError.length }"
        >
          <flat-pickr
            ref="flatpickr"
            v-model="dateRange"
            class="form-control date"
            :config="datepickerConfig"
            id="select-datepicker"
          />
          <span class="calendar-toggle" @click="toggleCalendar()">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.5498 5.88555C2.5498 4.80859 3.42285 3.93555 4.4998 3.93555H13.4998C14.5768 3.93555 15.4498 4.80859 15.4498 5.88555V13.5009C15.4498 14.5779 14.5768 15.4509 13.4998 15.4509H4.4998C3.42285 15.4509 2.5498 14.5779 2.5498 13.5009V5.88555ZM4.4998 4.83555C3.91991 4.83555 3.4498 5.30565 3.4498 5.88555V13.5009C3.4498 14.0808 3.91991 14.5509 4.4998 14.5509H13.4998C14.0797 14.5509 14.5498 14.0808 14.5498 13.5009V5.88555C14.5498 5.30565 14.0797 4.83555 13.4998 4.83555H4.4998Z"
                fill="#7483A1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.5498 7.61602C2.5498 7.36749 2.75128 7.16602 2.9998 7.16602H14.9998C15.2483 7.16602 15.4498 7.36749 15.4498 7.61602C15.4498 7.86454 15.2483 8.06602 14.9998 8.06602H2.9998C2.75128 8.06602 2.5498 7.86454 2.5498 7.61602Z"
                fill="#7483A1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.76885 2.55078C6.01738 2.55078 6.21885 2.75225 6.21885 3.00078V5.77001C6.21885 6.01854 6.01738 6.22001 5.76885 6.22001C5.52032 6.22001 5.31885 6.01854 5.31885 5.77001V3.00078C5.31885 2.75225 5.52032 2.55078 5.76885 2.55078Z"
                fill="#7483A1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2308 2.55078C12.4793 2.55078 12.6808 2.75225 12.6808 3.00078V5.77001C12.6808 6.01854 12.4793 6.22001 12.2308 6.22001C11.9822 6.22001 11.7808 6.01854 11.7808 5.77001V3.00078C11.7808 2.75225 11.9822 2.55078 12.2308 2.55078Z"
                fill="#7483A1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.23178 10.1552C6.23178 10.5375 5.92183 10.8475 5.53948 10.8475C5.15712 10.8475 4.84717 10.5375 4.84717 10.1552C4.84717 9.77285 5.15712 9.46289 5.53948 9.46289C5.92183 9.46289 6.23178 9.77285 6.23178 10.1552ZM7.84762 13.1552C8.22997 13.1552 8.53993 12.8453 8.53993 12.4629C8.53993 12.0806 8.22997 11.7706 7.84762 11.7706C7.46527 11.7706 7.15531 12.0806 7.15531 12.4629C7.15531 12.8453 7.46527 13.1552 7.84762 13.1552ZM5.53948 13.1552C5.92183 13.1552 6.23178 12.8453 6.23178 12.4629C6.23178 12.0806 5.92183 11.7706 5.53948 11.7706C5.15712 11.7706 4.84717 12.0806 4.84717 12.4629C4.84717 12.8453 5.15712 13.1552 5.53948 13.1552ZM10.8476 12.4629C10.8476 12.8453 10.5377 13.1552 10.1553 13.1552C9.77296 13.1552 9.463 12.8453 9.463 12.4629C9.463 12.0806 9.77296 11.7706 10.1553 11.7706C10.5377 11.7706 10.8476 12.0806 10.8476 12.4629ZM12.463 13.1552C12.8454 13.1552 13.1553 12.8453 13.1553 12.4629C13.1553 12.0806 12.8454 11.7706 12.463 11.7706C12.0807 11.7706 11.7707 12.0806 11.7707 12.4629C11.7707 12.8453 12.0807 13.1552 12.463 13.1552ZM7.84807 10.8475C8.23042 10.8475 8.54038 10.5375 8.54038 10.1552C8.54038 9.77285 8.23042 9.46289 7.84807 9.46289C7.46572 9.46289 7.15576 9.77285 7.15576 10.1552C7.15576 10.5375 7.46572 10.8475 7.84807 10.8475ZM10.8475 10.1552C10.8475 10.5375 10.5375 10.8475 10.1552 10.8475C9.77285 10.8475 9.46289 10.5375 9.46289 10.1552C9.46289 9.77285 9.77285 9.46289 10.1552 9.46289C10.5375 9.46289 10.8475 9.77285 10.8475 10.1552ZM12.4623 10.8475C12.8447 10.8475 13.1546 10.5375 13.1546 10.1552C13.1546 9.77285 12.8447 9.46289 12.4623 9.46289C12.08 9.46289 11.77 9.77285 11.77 10.1552C11.77 10.5375 12.08 10.8475 12.4623 10.8475Z"
                fill="#7483A1"
              />
            </svg>
          </span>
          <span v-if="dateRangeError.length" class="date-range__error">
            {{ dateRangeError }}
          </span>
        </div>
      </div>
    </div>
    <div class="dashboard__graph">
      <div class="graph__wrapper">
        <VueApexCharts
          :key="graphKey"
          :options="dashboardGraph"
          :series="dashboardGraph.series"
          height="250"
        />
      </div>
    </div>
    <div class="dashboard__table">
      <div class="dashboard__table__header">
        <div class="filter__tags">
          <div v-if="filterSelectedCount('table')" class="filter-tag__table">
            <span>Table ({{ filterSelectedCount('table') }}) </span>
            <img
              src="@/assets/svgs/close.svg"
              class="filter-tag__close"
              @click="clearFilter('table')"
            />
          </div>
          <div
            v-if="columnFilters.processed.applied"
            class="filter-tag__processed"
          >
            <span>Processed</span>
            <img
              src="@/assets/svgs/close.svg"
              class="filter-tag__close"
              @click="clearFilter('processed')"
            />
          </div>
          <div
            v-if="filterSelectedCount('timePeriod')"
            class="filter-tag__time-period"
          >
            <span>Time Period ({{ filterSelectedCount('timePeriod') }}) </span>
            <img
              src="@/assets/svgs/close.svg"
              class="filter-tag__close"
              @click="clearFilter('timePeriod')"
            />
          </div>
        </div>
        <div class="export-data">
          <b-dropdown
            variant="light"
            size="sm"
            :disabled="
              isLoading || exporting || dashboardTable.totalRecords < 1
            "
          >
            <template #button-content>
              <svg
                width="12"
                height="14"
                viewBox="0 0 12 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.35773 0.549805L10.4785 4.67055V6.9998C10.4785 7.24833 10.277 7.44981 10.0285 7.44981C9.77995 7.44981 9.57847 7.24833 9.57847 6.9998V5.30695H7.67133C6.59438 5.30695 5.72133 4.4339 5.72133 3.35695V1.4498H2.0999C1.52 1.4498 1.0499 1.91991 1.0499 2.4998V11.4998C1.0499 12.0797 1.52 12.5498 2.0999 12.5498H10.0285C10.277 12.5498 10.4785 12.7513 10.4785 12.9998C10.4785 13.2483 10.277 13.4498 10.0285 13.4498H2.0999C1.02295 13.4498 0.149902 12.5768 0.149902 11.4998V2.4998C0.149902 1.42285 1.02295 0.549805 2.0999 0.549805H6.35773ZM6.62133 2.0862V3.35695C6.62133 3.93685 7.09143 4.40695 7.67133 4.40695H8.94208L6.62133 2.0862Z"
                />
                <path
                  d="M4.86419 9.99981C4.86419 9.75128 5.06566 9.5498 5.31419 9.5498H10.4733L10.0826 8.9637C9.94475 8.75691 10.0006 8.47752 10.2074 8.33966C10.4142 8.2018 10.6936 8.25768 10.8314 8.46447L11.6847 9.74439C11.6875 9.74844 11.6903 9.75254 11.6929 9.75669L11.855 9.9998L11.6931 10.2427C11.6903 10.247 11.6875 10.2512 11.6846 10.2554L10.8314 11.5351C10.6936 11.7419 10.4142 11.7978 10.2074 11.6599C10.0006 11.5221 9.94475 11.2427 10.0826 11.0359L10.4733 10.4498H5.31419C5.06566 10.4498 4.86419 10.2483 4.86419 9.99981Z"
                />
              </svg>
            </template>
            <b-dropdown-item
              id="export-xlsx"
              @click="downloadFile(exportTypes.xlsx)"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z"
                  fill="#667C99"
                />
                <path
                  d="M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z"
                  fill="#667C99"
                />
              </svg>
              <span>Export to XLSX</span>
            </b-dropdown-item>
            <b-dropdown-item
              id="export-csv"
              @click="downloadFile(exportTypes.csv)"
            >
              <svg
                width="14"
                height="13"
                viewBox="0 0 14 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z"
                  fill="#667C99"
                />
                <path
                  d="M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z"
                  fill="#667C99"
                />
              </svg>
              <span>Export to CSV</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="table__wrapper dashboard__table">
        <CustomTable
          type-of-table="dashboard"
          :data="dashboardTable"
          :column-filters="columnFilters"
          :is-pagination-enabled="true"
          :is-loading="isLoading"
          table-mode="local"
        >
          <template #row-data="{ data }">
            <DashboardRow :data="data" />
          </template>
          <template #column-data="{ data }">
            <span
              v-if="columnFilters[data.column.field]"
              class="filter__header"
            >
              {{ data.column.label }}
              <FilterDropdown
                :id="`${data.column.field}-filter`"
                :filter="columnFilters[data.column.field]"
                @apply-filters="applyFilters"
              />
            </span>
            <span v-else>
              {{ data.column.label }}
            </span>
          </template>
        </CustomTable>
      </div>
    </div>
  </div>
</template>

<script>
import FilterDropdown from '@/components/table-components/FilterDropdown.vue'
import DashboardRow from '@/components/table-data/DashboardRow.vue'
import store from '@/store'
import { CustomTable } from '@nodus/utilities-front'
import { BDropdown, BDropdownItem, BTooltip } from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, reactive, ref } from 'vue'
import VueApexCharts from 'vue-apexcharts'
import FlatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import {
  createDownloadLink,
  defaultPeriodicity,
  defaultUnit,
  exportTypes,
  periodicity,
  units,
} from './dashboardConfig'

export default {
  name: 'Dashboard',
  components: {
    vSelect,
    VueApexCharts,
    BTooltip,
    BDropdown,
    BDropdownItem,
    FlatPickr,
    CustomTable,
    FilterDropdown,
    DashboardRow,
  },
  setup(_, { refs }) {
    const root = getCurrentInstance().proxy
    const dateRange = ref()
    let dateFrom = ''
    let dateTo = ''
    const isLoading = ref(true)
    const exporting = ref(false)
    const graphKey = ref('graph')
    const { organizationId } = root.$route.params
    const tables = computed(() => store.getters['dashboard/allTables'])
    const dashboardGraph = computed(() => store.getters['dashboard/graph'])
    const dashboardTable = computed(() => store.getters['dashboard/table'])
    const columnFilters = computed(
      () => store.getters['dashboard/secondaryFilters']
    )
    const dateRangeError = ref('')

    const primaryFilters = reactive({
      selected: {
        table: [],
        unit: defaultUnit,
        period: defaultPeriodicity,
      },
      options: {
        tables: [],
        units,
        periodicity,
      },
    })

    const updateApi = async () => {
      isLoading.value = true
      graphKey.value = 'graph'
      dashboardTable.value.page = 1
      const { table, unit, period } = primaryFilters.selected
      await store.dispatch('dashboard/getGraph', {
        organizationId,
        dateFrom,
        dateTo,
        tables: table,
        unit: unit.id,
        period: period.id,
      })
      await store.dispatch('dashboard/getTable', {
        organizationId,
        dateFrom,
        dateTo,
        tables: table,
        unit: unit.id,
        period: period.id,
      })
      graphKey.value = 'updated'
      isLoading.value = false
    }

    onMounted(async () => {
      getLastWeek()
      const { table, unit, period } = primaryFilters.selected
      await store
        .dispatch('dashboard/getAllTables', { organizationId })
        .then(() => (primaryFilters.options.tables = tables.value))
      await store.dispatch('dashboard/getTable', {
        organizationId,
        dateFrom,
        dateTo,
        tables: table,
        unit: unit.id,
        period: period.id,
      })
      await store.dispatch('dashboard/getGraph', {
        organizationId,
        dateFrom,
        dateTo,
        tables: table,
        unit: unit.id,
        period: period.id,
      })
      graphKey.value = 'updated'
      isLoading.value = false
    })

    const downloadFile = async (format) => {
      exporting.value = true
      const { table, unit, period } = primaryFilters.selected
      const fileName = `Metrics - ${dateFrom}-${dateTo}.${
        format == 1 ? 'xlsx' : 'csv'
      }`
      const response = await store.dispatch('dashboard/getFile', {
        organizationId,
        dateFrom,
        dateTo,
        tables: table,
        unit: unit.id,
        period: period.id,
        format,
      })
      createDownloadLink(response, fileName)
      exporting.value = false
    }

    const getLastWeek = () => {
      const today = new Date()
      today.setDate(today.getDate() - 6)
      const lastWeek = new Date(today)
      dateFrom = `${lastWeek.getFullYear()}-${
        lastWeek.getMonth() + 1
      }-${lastWeek.getDate()}`
      dateTo = `${new Date().getFullYear()}-${
        new Date().getMonth() + 1
      }-${new Date().getDate()}`
      return `[${dateFrom} to ${dateTo}]`
    }

    const validateWeeklyRange = () => {
      const from = new Date(dateFrom)
      const to = new Date(dateTo)
      dateRangeError.value = ''

      if (primaryFilters.selected.period.id !== 1) updateApi()
      else if (dateFrom !== dateTo && isValidDate(from, to)) updateApi()
      else dateRangeError.value = 'Please select a weekly range!'
    }

    const datepickerConfig = {
      mode: 'range',
      defaultDate: getLastWeek(),
      maxDate: new Date().getTime() + 1,
      altInput: true,
      altFormat: 'd M, Y',
      onClose: (selectedDates, dateStr, instance) => {
        dateFrom = dateStr
        const formatDate = dateStr.split(' to ')
        if (formatDate.length > 1) {
          // eslint-disable-next-line no-extra-semi
          ;[dateFrom, dateTo] = formatDate
        } else {
          dateTo = dateFrom
          instance.setDate([dateTo, dateFrom], true)
        }

        validateWeeklyRange()
      },
      onDayCreate: (dObj, dStr, fp, dayElem) => {
        if (primaryFilters.selected.period.id === 1 && dObj.length === 1) {
          if (isValidDate(dObj[0], dayElem.dateObj))
            dayElem.classList.remove('weekly__not-allowed')
          else dayElem.classList.add('weekly__not-allowed')
        }
      },
    }

    const isValidDate = (from, to) => {
      const forwardDate = new Date(from)
      const previousDate = new Date(from)
      forwardDate.setDate(from.getDate() + 6)
      previousDate.setDate(from.getDate() + 1)
      if (from < to) return to.getDay() === forwardDate.getDay()
      else return to.getDay() === previousDate.getDay()
    }

    const toggleCalendar = () => refs.flatpickr.fp.toggle()

    const filterFunction = () => store.dispatch('dashboard/filterRows')

    const clearFilter = (field) => {
      const filter = columnFilters.value[field]
      if (filter.type === 'range') {
        filter.from = null
        filter.to = null
        filter.applied = false
      } else {
        columnFilters.value[field].options.forEach(
          (cf) => (cf.selected = false)
        )
        columnFilters.value[field].searchTerm = ''
      }
      filterFunction()
    }

    const filterSelectedCount = (field) =>
      columnFilters.value[field].options.filter((cf) => cf.selected).length

    const applyFilters = () => {
      // TODO
      // props.data.page = 1;
      filterFunction()
    }

    return {
      dateRange,
      datepickerConfig,
      toggleCalendar,
      dashboardGraph,
      dashboardTable,
      columnFilters,
      isLoading,
      primaryFilters,
      clearFilter,
      filterSelectedCount,
      updateApi,
      exportTypes,
      downloadFile,
      exporting,
      graphKey,
      filterFunction,
      dateRangeError,
      validateWeeklyRange,
      applyFilters,
    }
  },
}
</script>

<style lang="scss">
@import 'flatpickr/dist/flatpickr.css';
@import '@/assets/scss/views/dashboard';
.dashboard__table {
  .vgt-wrap .vgt-inner-wrap {
    min-height: unset;
  }

  .vgt-wrap .vgt-responsive {
    table tbody tr td {
      padding: 8px 12px;
    }
  }
}
</style>
