import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import { destructJSONObject } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Table',
          field: 'name',
          width: '30%',
        },
        {
          label: 'Size',
          field: 'size',
          type: 'number',
          width: '30%',
        },
        {
          label: 'Rows Number',
          field: 'rows',
          type: 'number',
          width: '40%',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
    },
    initialQuery: '',
    canQuery: false,
    canPreview: false,
  },
  getters: {
    getData: (state) => state.table,
    getInitialQuery: (state) => state.initialQuery,
    getCanQuery: (state) => state.canQuery,
    getCanPreview: (state) => state.canPreview,
  },
  actions: {
    async getData({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/tables`
      )
      const canPreview = destructJSONObject(response, 'canPreview')
      const canQuery = destructJSONObject(response, 'canQuery')
      commit('SET_WORKSPACE_DATA', destructJSONObject(response, 'tables'))
      commit('SET_PERMISSION_DATA', { canPreview, canQuery })
    },
    setInitialQuery({ commit }, query) {
      commit('SET_INITIAL_QUERY', query)
    },
  },
  mutations: {
    SET_WORKSPACE_DATA(state, tables) {
      state.table.rows = tables
    },
    SET_PERMISSION_DATA(state, { canQuery, canPreview }) {
      state.canQuery = canQuery
      state.canPreview = canPreview
    },
    SET_INITIAL_QUERY(state, query) {
      state.initialQuery = query
    },
  },
}
