var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "content__wrapper new-query__wrapper",
      attrs: { id: "content__wrapper" },
    },
    [
      _c(
        "div",
        {
          staticClass: "new-query__header",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "biskql-workspace",
              })
            },
          },
        },
        [
          _c("feather-icon", {
            staticClass: "pagination__icon",
            attrs: { icon: "ChevronLeftIcon", size: "16" },
          }),
          _c(
            "span",
            { staticClass: "new-query__header-text", attrs: { id: "go-back" } },
            [_vm._v("Go Back")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "workspace__new-query", attrs: { id: "editor" } },
        [
          _c(
            "splitpanes",
            { ref: "splitRef", staticClass: "default-theme" },
            [
              _c(
                "pane",
                {
                  staticClass: "workspace__custom-pane schema__pane",
                  class: { "schema__pane--collapsed": _vm.schemaCollapsed },
                  attrs: { "min-size": "15", size: "25" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "schema__pane__content" },
                    [
                      _vm.schemaCollapsed
                        ? _c("div", { staticClass: "content_folder-icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/svgs/folder.svg"),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _c("Schema", {
                        attrs: {
                          hidden: _vm.schemaCollapsed,
                          organization: _vm.organizationId,
                          "is-loading": _vm.isSchemaLoading,
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "schema__pane__footer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "schema__pane__footer_content",
                        on: {
                          click: function ($event) {
                            !_vm.isSchemaLoading
                              ? _vm.toggleSchemaCollapse()
                              : {}
                          },
                        },
                      },
                      [
                        _c("img", {
                          class: {
                            "collapse_icon--flipped": _vm.schemaCollapsed,
                          },
                          attrs: { src: require("@/assets/svgs/collapse.svg") },
                        }),
                        !_vm.schemaCollapsed
                          ? _c(
                              "span",
                              {
                                staticClass: "ml-50 unselectable-text",
                                attrs: { id: "collapse-menu" },
                              },
                              [_vm._v("Collapse menu")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]
              ),
              _c(
                "pane",
                { attrs: { "min-size": "60" } },
                [
                  _c(
                    "b-tabs",
                    { ref: "editorTabs", attrs: { align: "left" } },
                    [
                      _c("template", { slot: "tabs-end" }, [
                        _c("div", { staticClass: "new-button__container" }, [
                          _c("div", { staticClass: "new-button__content" }, [
                            _c(
                              "span",
                              {
                                staticClass: "unselectable-text",
                                attrs: { id: "new-query" },
                                on: {
                                  click: function ($event) {
                                    return _vm.newTab()
                                  },
                                },
                              },
                              [_vm._v("+ New Query")]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._l(_vm.allEditorTabs, function (editor, index) {
                        return _c(
                          "b-tab",
                          {
                            key: editor.id,
                            attrs: { id: `editor-tab_` + editor.id },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _vm._v(" " + _vm._s(`Editor ${editor.id}`) + " "),
                              _vm.allEditorTabs.length > 1
                                ? _c("img", {
                                    staticClass: "tab-close",
                                    attrs: {
                                      src: require("@/assets/svgs/close.svg"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.closeTab(index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]),
                            _c(
                              "splitpanes",
                              { attrs: { horizontal: "" } },
                              [
                                _c(
                                  "pane",
                                  { staticClass: "editor__pane" },
                                  [
                                    _c("Editor", {
                                      attrs: {
                                        index: index,
                                        content:
                                          editor.id === 1
                                            ? _vm.initialQuery
                                            : "",
                                        data: editor,
                                      },
                                      on: {
                                        "run-query": _vm.executeQuery,
                                        "cancel-query": function ($event) {
                                          return _vm.cancel("execute", editor)
                                        },
                                        "validate-query": _vm.handleValidation,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                editor.execute.showResults
                                  ? _c(
                                      "pane",
                                      {
                                        staticClass:
                                          "workspace__custom-pane query-results__pane",
                                        attrs: { size: "50" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "query-results__pane__header",
                                          },
                                          [_vm._v("Query results")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "query-results__pane__content",
                                          },
                                          [
                                            _c("Results", {
                                              attrs: {
                                                data: editor,
                                                index: index,
                                              },
                                              on: {
                                                "update-table": function (
                                                  $event
                                                ) {
                                                  return _vm.executeQuery(
                                                    editor.execute.query,
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }