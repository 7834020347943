<script>
import ResultsTableRow from '@/components/table-data/ResultsTableRow.vue';
import {ref} from "vue";
import VirtualList from "vue-virtual-scroll-list";

export default {
  name: "ResultsTable",
  emits: ["update-table"],
  components: {
    VirtualList,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup(props, { emit }) {
    const rowIndex = (index) => {
      return props.data.page * props.data.perPage + index + 1;
    };

    const changePerPage = () => {
      props.data.page = 0;
      emit("update-table");
    };

    const navigate = (direction) => {
      if (direction === "next") props.data.page += 1;
      else props.data.page -= 1;
      emit("update-table");
    };

    const cantNavigate = (direction) => {
      if (direction === "next") return props.data.isLastPage;

      return props.data.page === 0;
    };

    const columnStartingPosition = ref(0);
    const columnWidth = ref(0);
    const columnToResize = ref("");
    const columnCellIndex = ref(null);
    const resizedColumns = ref([]);

    const mouseDownHandler = (e) => {
      columnCellIndex.value = e.target.getAttribute("colIndex");
      columnToResize.value = e.target.offsetParent;
      columnStartingPosition.value = e.clientX;
      columnWidth.value = columnToResize.value.offsetWidth;
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);

      if (
        !resizedColumns.value.find(
          (rc) => rc.colIndex === columnCellIndex.value
        )
      ) {
        resizedColumns.value.push({
          colIndex: columnCellIndex.value,
          width: 180,
        });
      }
    };

    const mouseMoveHandler = (e) => {
      const difference = e.clientX - columnStartingPosition.value;
      const width =
        columnWidth.value + difference < 180
          ? 180
          : columnWidth.value + difference;
      const column = resizedColumns.value.find(
        (rc) => rc.colIndex === columnCellIndex.value
      );
      column.width = width > 600 ? 600 : width;

      if (width > 400) column.styleClass = "text__wrap";
      else if (width < 400) column.styleClass = "text__nowrap";
    };

    const mouseUpHandler = () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
      columnToResize.value = "";
    };

    const itemComponent = ResultsTableRow;

    return {
      changePerPage,
      navigate,
      cantNavigate,
      rowIndex,
      mouseDownHandler,
      mouseUpHandler,
      itemComponent,
      resizedColumns,
    };
  },
};
</script>

<template>
  <div class="table-and-pagination__container">
    <div class="results__table-container">
      <VirtualList
        :data-key="'index'"
        :data-sources="data.rows"
        :data-component="itemComponent"
        :extra-props="{ resizedColumns }"
        :estimate-size="38"
        class="scrollable__area"
        item-class="table__row"
        wrap-class="table__body"
        header-class="thead"
      >
        <template #header>
          <div class="table__row">
            <div>
              <div class="row-number__index table__cell table__header">Row</div>
              <div
                v-for="(header, index) in data.headers"
                :key="index"
                class="table__cell table__header"
                :style="`min-width: ${
                  resizedColumns.find((rc) => +rc.colIndex === index)
                    ? resizedColumns.find((rc) => +rc.colIndex === index).width
                    : 180
                }px`"
              >
                <span> {{ header }}</span>
                <div
                  :colIndex="index"
                  class="resizer"
                  @mousedown="mouseDownHandler($event)"
                  @mouseup="mouseUpHandler($event)"
                >
                  <div class="divider"></div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </VirtualList>
    </div>
    <div class="table__pagination space-between">
      <div class="table__pagination-left">
        <span class="text-nowrap"> {{ $t("rowsPerPage") }}: </span>
        <b-form-select
          id="per-page-dropdown"
          v-model="data.perPage"
          :options="['50', '100', '200']"
          class="mx-25 per-page__input"
          @input="changePerPage"
        />
      </div>
      <div class="d-flex">
        <div class="pagination__right-container">
          <b-button
            id="previous-page"
            variant="outline-secondary"
            class="navigate__button navigate__button-previous"
            :disabled="cantNavigate('previous')"
            @click="navigate('previous')"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="16"
              class="pagination__icon"
            />
            <span
              class="pagination__text"
              :class="{
                'pagination__text--disabled': cantNavigate('previous'),
              }"
              >{{ $t("previous") }}</span
            >
          </b-button>
          <b-button
            id="next-page"
            variant="outline-secondary"
            class="navigate__button navigate__button-next"
            :disabled="cantNavigate('next')"
            @click="navigate('next')"
          >
            <span
              class="pagination__text"
              :class="{ 'pagination__text--disabled': cantNavigate('next') }"
              >{{ $t("next") }}</span
            >
            <feather-icon
              icon="ChevronRightIcon"
              size="16"
              class="pagination__icon"
            />
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/views/shared/resultstable.scss";
</style>
