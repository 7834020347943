<template>
  <div class="workspace-editor">
    <div class="workspace-editor__header">
      <b-spinner
        v-if="data.validate.loading"
        small
        class="validation__spinner"
      />
      <div v-else-if="!data.validate.query"></div>
      <div
        v-else-if="data.validate.valid"
        class="workspace-editor__header-info"
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.5785 8.47729C13.8421 8.15777 13.7968 7.68507 13.4773 7.42147C13.1578 7.15787 12.6851 7.2032 12.4215 7.52271L8.83904 11.8651L7.54715 10.487C7.26385 10.1849 6.78923 10.1695 6.48704 10.4528C6.18486 10.7361 6.16955 11.2108 6.45285 11.513L7.9391 13.0983C8.45454 13.6481 9.33565 13.6202 9.81524 13.0388L13.5785 8.47729Z"
            fill="#36B37E"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10Z"
            fill="#36B37E"
          />
        </svg>
        <span class="info-text"
          >This query processes approximately
          {{ data.validate.processingSize }}.</span
        >
      </div>
      <div v-else class="workspace-editor__header-info">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6C9.25 5.58579 9.58579 5.25 10 5.25Z"
            fill="#E34850"
          />
          <path
            d="M10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14Z"
            fill="#E34850"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 13.5899 6.41015 16.5 10 16.5Z"
            fill="#E34850"
          />
        </svg>
        <span
          v-if="data.validate.error.message.length < 250"
          class="info-text"
          >{{ data.validate.error.message }}</span
        >
        <span v-else class="info-text">
          {{
            showMore
              ? data.validate.error.message
              : data.validate.error.message.substring(0, 250)
          }}
          <span class="show-more__toggle" @click="toggleShowMore">{{
            showMore ? "show less" : "show more"
          }}</span>
        </span>
      </div>
      <div
        class="workspace-editor__header-run"
        @click="data.execute.loading ? cancelQuery() : runQuery()"
      >
        <b-button variant="outline-primary" class="run-button" id="run-query">
          <div :hidden="!data.execute.loading">
            <img src="@/assets/svgs/loader.svg" class="svg__rotate-animation" />
          </div>
          <img :hidden="data.execute.loading" src="@/assets/svgs/run.svg" />
          <span>{{ data.execute.loading ? "Cancel" : "Run" }}</span>
        </b-button>
      </div>
    </div>
    <div class="workspace-editor__code">
      <!-- eslint-disable-next-line vue/no-textarea-mustache -->
      <textarea :id="'codemirror-' + index" style="white-space: pre-line">{{
        content
      }}</textarea>
    </div>
  </div>
</template>

<script>
import {
debounce
} from '@nodus/utilities-front';
import {BButton, BSpinner} from "bootstrap-vue";
import * as CodeMirror from "codemirror";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/addon/hint/sql-hint";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint.js";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/sql/sql";
import {onMounted, ref} from "vue";

export default {
  emits: ["run-query", "cancel-query", "validate-query"],
  name: "EditorCode",
  components: {
    BButton,
    BSpinner,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: "",
    },
    data: {
      type: [Array, Object],
    },
  },
  setup(props, ctx) {
    const showMore = ref(false);
    let cm;
    const cmCfg = {
      lineNumbers: true,
      mode: "sql",
      extraKeys: { "Ctrl-Space": "autocomplete" },
      gutters: ["CodeMirror-lint-markers"],
      lint: {
        highlightLines: true,
        lintOnChange: false,
        delay: 0,
      },
      editorData: props,
    };

    const emitValidate = debounce(
      (type, selectedText, selection, editorInstance) => {
        ctx.emit(
          "validate-query",
          type,
          selectedText,
          selection,
          editorInstance
        );
      },
      750
    );

    onMounted(() => {
      const txtArea = document.getElementById(`codemirror-${props.index}`);
      cm = CodeMirror.fromTextArea(txtArea, cmCfg);
      setTimeout(() => {
        cm.refresh();
        cm.focus();

        if (props.content) cm.setCursor({ line: 0, ch: 7 });
        else cm.setCursor(cm.lineCount(), 0);

        cm.on("beforeSelectionChange", (editorInstance, selection) => {
          const { anchor, head } = selection.ranges[0];
          let from, to;

          if (anchor.line > head.line || anchor.ch > head.ch) {
            from = head;
            to = anchor;
          } else {
            from = anchor;
            to = head;
          }

          const selectedText = editorInstance.getRange(from, to);

          if (selectedText)
            emitValidate("select", selectedText, selection, editorInstance);
          else if (editorInstance.getSelection())
            emitValidate(
              "change",
              editorInstance.getValue(),
              selection,
              editorInstance
            );
        });

        cm.on("change", (editorInstance, changeObj) => {
          emitValidate(
            "change",
            editorInstance.getValue(),
            changeObj,
            editorInstance
          );
        });
      }, 50);
    });

    const runQuery = () => {
      props.data.table.page = 0;
      props.data.table.perPage = 100;
      const query = cm.getSelection() ? cm.getSelection() : cm.getValue();
      props.data.execute.query = query;
      ctx.emit("run-query", query, props.index);
    };

    const cancelQuery = () => {
      ctx.emit("cancel-query");
    };

    const toggleShowMore = () => (showMore.value = !showMore.value);

    return {
      runQuery,
      cancelQuery,
      showMore,
      toggleShowMore,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/views/editor-panes/editor";
</style>
