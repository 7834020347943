var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter__dropdown" },
    [
      _vm.filter.type === "range"
        ? _c(
            "b-dropdown",
            {
              staticClass: "filter-dropdown__icon",
              attrs: { id: `${_vm.id}-dropdown`, text: "Dropdown Button" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _c("img", {
                          attrs: { src: require("@/assets/svgs/filter.svg") },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3582643562
              ),
            },
            [
              _c("b-dropdown-text", [
                _c(
                  "div",
                  { staticClass: "range__filter" },
                  [
                    _c("b-form-input", {
                      staticClass: "form__input range__input",
                      attrs: { placeholder: "From", type: "number" },
                      model: {
                        value: _vm.filter.from,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "from", $$v)
                        },
                        expression: "filter.from",
                      },
                    }),
                    _c("span", { staticClass: "range__divider" }, [
                      _vm._v("-"),
                    ]),
                    _c("b-form-input", {
                      staticClass: "form__input range__input",
                      attrs: { placeholder: "To", type: "number" },
                      model: {
                        value: _vm.filter.to,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "to", $$v)
                        },
                        expression: "filter.to",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    staticClass: "range__apply-filter",
                    on: { click: _vm.applyRange },
                  },
                  [_vm._v("Apply")]
                ),
              ]),
            ],
            1
          )
        : _c(
            "b-dropdown",
            {
              staticClass: "filter-dropdown__icon",
              attrs: { id: `${_vm.id}-dropdown`, text: "Dropdown Button" },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c("img", {
                        attrs: { src: require("@/assets/svgs/filter.svg") },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "b-dropdown-text",
                [
                  _c("b-form-input", {
                    staticClass: "form__input manual__input",
                    attrs: {
                      placeholder: _vm.filter.placeholder,
                      type: "text",
                    },
                    model: {
                      value: _vm.filter.searchTerm,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "searchTerm", $$v)
                      },
                      expression: "filter.searchTerm",
                    },
                  }),
                  _c("div", { staticClass: "actions__container" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.filter.options.some(
                              (option) => !option.selected
                            )
                              ? _vm.selectAll()
                              : {}
                          },
                        },
                      },
                      [_vm._v("Select All")]
                    ),
                    _c("div", { staticClass: "dot" }),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.clearAll()
                          },
                        },
                      },
                      [_vm._v("Clear")]
                    ),
                  ]),
                ],
                1
              ),
              _vm.filter.options.length
                ? _c(
                    "b-dropdown-group",
                    { staticClass: "dropdown__scrollable-area" },
                    _vm._l(_vm.filteredOptions, function (option) {
                      return _c(
                        "b-dropdown-text",
                        {
                          key: option.id,
                          on: {
                            click: function ($event) {
                              return _vm.selectItem(option)
                            },
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            model: {
                              value: option.selected,
                              callback: function ($$v) {
                                _vm.$set(option, "selected", $$v)
                              },
                              expression: "option.selected",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(option.id))]),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c("div", { staticClass: "filter__empty-state" }, [
                    _c("span", [_vm._v("No filters to show...")]),
                  ]),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }