export const units = [
  {id: 0, unit: 'Bytes'},
  {id: 1, unit: 'KiB'},
  {id: 2, unit: 'MiB'},
  {id: 3, unit: 'GiB'},
  {id: 4, unit: 'TiB'},
  {id: 5, unit: 'PiB'}
]

export const defaultUnit = units[4]

export const periodicity = [
  {id: 0, period: 'Daily'},
  {id: 1, period: 'Weekly'},
  {id: 2, period: 'Monthly'}
]

export const defaultPeriodicity = periodicity[0]

export const exportTypes = {
  xlsx : 1,
  csv : 2
}

export const createDownloadLink = (file, fileName) => {
  const name = fileName
  const url = window.URL.createObjectURL(new Blob([file]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}