<script>
import store from '@/store'
import { computed } from 'vue'

export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup() {
    const headers = computed(() => store.getters['preview/getColumnHeaders'])

    return {
      headers,
    }
  },
}
</script>

<template>
  <span>
    {{ data.row[headers.indexOf(data.column.field)] }}
  </span>
</template>
