export const graphConfig = {
  series: [
    {
      name: 'Processing',
      data: [],
      labels: {
        formatter: data =>  data.toLocaleString()
      }
    }
  ],
  chart: {
    id: 'analytics',
    type: 'area',
    zoom: {
      enabled: false
    },
    dropShadow: {
      enabled: false,
      color: '#36B37E',
      top: 18,
      left: 7,
      blur: 10,
      opacity: 0.2
    },
    toolbar: {
      show: false
    }
  },
  colors: ['#FFDE33'],
  fill: {
    type: 'gradient',
    gradient: {
      opacityTo: 0.3,
      stops: [0, 90, 100]
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  title: {
    text: 'Processing',
    align: 'left',
    style: {
      fontSize: '20px',
      fontWeight: '700',
      fontFamily: 'Inter',
      color:  '#031C3C'
    }
  },
  grid: {
    borderColor: '#DADFE7',
    strokeDashArray: 8,
    row: {
      colors: ['transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
    padding: {
      left: 25,
    }
  },
  markers: {
    size: 0,
    colors: '#7483A1',
    strokeColors: '#7483A1',
    strokeOpacity: 0.2,
    strokeWidth: 10,
    hover: {
      size: 6
    }
  },
  yaxis: {
    // tickAmount: 4, //set this to lock y-axis steps
    labels: {
      show: true,
      formatter: series => {
        if (series < 1e3) return series.toFixed(2)
        if (series >= 1e3 && series < 1e6)
          return `${+(series / 1e3).toFixed(2)}K (thousand)`
        if (series >= 1e6 && series < 1e9)
          return `${+(series / 1e6).toFixed(2)}M (million)`
        if (series >= 1e9 && series < 1e12)
          return `${+(series / 1e9).toFixed(2)}B (billion)`
        if (series >= 1e12 && series < 1e15)
          return `${+(series / 1e12).toFixed(2)}T (trillion)`
        if (series >= 1e15 && series < 1e18)
          return `${+(series / 1e15).toFixed(2)}Q (quadrillion)`
        return series.toLocaleString()
      },
      style: {
        colors: '#A3B0C2',
        cssClass: 'analytics-chart__yaxis'
      }
    }
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      style: {
        colors: '#A3B0C2'
      }
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: true,
      width: 1,
      stroke: {
        dashArray: 0,
        color: '#7483A1'
      }
    },
    tooltip: {
      enabled: true
    }
  },
  noData: {
    text: 'Loading...'
  }
}

export const units = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB']

export const timePeriodPlaceholder = ['dd/mm/yyyy', 'dd/mm/yyyy - dd/mm/yyyy', 'month yyyy']

export const formatMetricsRequest = (tables, unit, timePeriod, dateFrom, dateTo) => {
  let queryParamString = `Unit=${unit}&TimePeriod=${timePeriod}&DateFrom=${dateFrom}&DateTo=${dateTo}`
  
  if (tables && tables.length > 0) {
    const eURI = encodeURIComponent
    const tablesQuery = Object.keys(tables).map(key => `Tables=${eURI(tables[key].name)}`).join('&')
    return `${tablesQuery}&${queryParamString}`
  }

  return queryParamString
}