<template>
  <div id="app" class="bisko-app__container">
    <div class="content__container">
      <app-bread-crumb module="biskql" />
      <transition name="zoom-fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { AppBreadCrumb } from '@nodus/utilities-front'

export default {
  components: {
    AppBreadCrumb
  }
}
</script>

<style lang="scss">
.bisko-app__biskql {
  opacity: 1;

  .content__container {
    height: calc(100vh - 4.5rem);
  }
}

.content__wrapper {
  max-width: 1440px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
</style>
