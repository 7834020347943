<script>
export default {
  components: {
  },
  props: {
    data: {
      type: [Array, Object]
    }
  }
}
</script>

<template>
  <div>
    <span v-if="data.column.field === 'processed'">
      {{ `${data.row.processed.toLocaleString()} ${data.row.processedUnit}`}}
    </span>
    <span v-else>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>