var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row-number__index table__cell" }, [
        _vm._v(_vm._s(_vm.source.index)),
      ]),
      _vm._l(_vm.source.data, function (cell, cellIndex) {
        return _c(
          "div",
          {
            key: cellIndex,
            staticClass: "table__cell",
            class: _vm.resizedColumns.find((rc) => +rc.colIndex === cellIndex)
              ? _vm.resizedColumns.find((rc) => +rc.colIndex === cellIndex)
                  .styleClass
              : "text__nowrap",
            style: `min-width: ${
              _vm.resizedColumns.find((rc) => +rc.colIndex === cellIndex)
                ? _vm.resizedColumns.find((rc) => +rc.colIndex === cellIndex)
                    .width
                : 180
            }px`,
            attrs: { id: `cell-${cellIndex}` },
          },
          [_c("span", [_vm._v(_vm._s(cell))])]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }