var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "workspace-editor" }, [
    _c(
      "div",
      { staticClass: "workspace-editor__header" },
      [
        _vm.data.validate.loading
          ? _c("b-spinner", {
              staticClass: "validation__spinner",
              attrs: { small: "" },
            })
          : !_vm.data.validate.query
          ? _c("div")
          : _vm.data.validate.valid
          ? _c("div", { staticClass: "workspace-editor__header-info" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M13.5785 8.47729C13.8421 8.15777 13.7968 7.68507 13.4773 7.42147C13.1578 7.15787 12.6851 7.2032 12.4215 7.52271L8.83904 11.8651L7.54715 10.487C7.26385 10.1849 6.78923 10.1695 6.48704 10.4528C6.18486 10.7361 6.16955 11.2108 6.45285 11.513L7.9391 13.0983C8.45454 13.6481 9.33565 13.6202 9.81524 13.0388L13.5785 8.47729Z",
                      fill: "#36B37E",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM16.5 10C16.5 13.5899 13.5899 16.5 10 16.5C6.41015 16.5 3.5 13.5899 3.5 10C3.5 6.41015 6.41015 3.5 10 3.5C13.5899 3.5 16.5 6.41015 16.5 10Z",
                      fill: "#36B37E",
                    },
                  }),
                ]
              ),
              _c("span", { staticClass: "info-text" }, [
                _vm._v(
                  "This query processes approximately " +
                    _vm._s(_vm.data.validate.processingSize) +
                    "."
                ),
              ]),
            ])
          : _c("div", { staticClass: "workspace-editor__header-info" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6C9.25 5.58579 9.58579 5.25 10 5.25Z",
                      fill: "#E34850",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M10 14C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44772 14 10 14Z",
                      fill: "#E34850",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      "fill-rule": "evenodd",
                      "clip-rule": "evenodd",
                      d: "M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 16.5C13.5899 16.5 16.5 13.5899 16.5 10C16.5 6.41015 13.5899 3.5 10 3.5C6.41015 3.5 3.5 6.41015 3.5 10C3.5 13.5899 6.41015 16.5 10 16.5Z",
                      fill: "#E34850",
                    },
                  }),
                ]
              ),
              _vm.data.validate.error.message.length < 250
                ? _c("span", { staticClass: "info-text" }, [
                    _vm._v(_vm._s(_vm.data.validate.error.message)),
                  ])
                : _c("span", { staticClass: "info-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showMore
                            ? _vm.data.validate.error.message
                            : _vm.data.validate.error.message.substring(0, 250)
                        ) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "show-more__toggle",
                        on: { click: _vm.toggleShowMore },
                      },
                      [_vm._v(_vm._s(_vm.showMore ? "show less" : "show more"))]
                    ),
                  ]),
            ]),
        _c(
          "div",
          {
            staticClass: "workspace-editor__header-run",
            on: {
              click: function ($event) {
                _vm.data.execute.loading ? _vm.cancelQuery() : _vm.runQuery()
              },
            },
          },
          [
            _c(
              "b-button",
              {
                staticClass: "run-button",
                attrs: { variant: "outline-primary", id: "run-query" },
              },
              [
                _c("div", { attrs: { hidden: !_vm.data.execute.loading } }, [
                  _c("img", {
                    staticClass: "svg__rotate-animation",
                    attrs: { src: require("@/assets/svgs/loader.svg") },
                  }),
                ]),
                _c("img", {
                  attrs: {
                    hidden: _vm.data.execute.loading,
                    src: require("@/assets/svgs/run.svg"),
                  },
                }),
                _c("span", [
                  _vm._v(_vm._s(_vm.data.execute.loading ? "Cancel" : "Run")),
                ]),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "workspace-editor__code" }, [
      _c(
        "textarea",
        {
          staticStyle: { "white-space": "pre-line" },
          attrs: { id: "codemirror-" + _vm.index },
        },
        [_vm._v(_vm._s(_vm.content))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }