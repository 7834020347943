import { render, staticRenderFns } from "./NewQuery.vue?vue&type=template&id=4b355d7c&"
import script from "./NewQuery.vue?vue&type=script&lang=js&"
export * from "./NewQuery.vue?vue&type=script&lang=js&"
import style0 from "./NewQuery.vue?vue&type=style&index=0&id=4b355d7c&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4b355d7c')) {
      api.createRecord('4b355d7c', component.options)
    } else {
      api.reload('4b355d7c', component.options)
    }
    module.hot.accept("./NewQuery.vue?vue&type=template&id=4b355d7c&", function () {
      api.rerender('4b355d7c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/NewQuery.vue"
export default component.exports