var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", { staticClass: "schema__container" }, [
        _c(
          "div",
          { staticClass: "container--relative" },
          [
            _c("img", { attrs: { src: require("@/assets/svgs/table.svg") } }),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.collapse-all",
                    modifiers: { "collapse-all": true },
                  },
                ],
                staticClass: "table__name table__name--margin",
              },
              [_vm._v(_vm._s(_vm.datasetName))]
            ),
            _c(
              "b-collapse",
              {
                staticClass: "tables__container",
                attrs: { visible: "", id: "collapse-all" },
              },
              _vm._l(_vm.schemaData, function (table, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "container--relative" },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/svgs/table.svg") },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "b-toggle",
                            rawName: "v-b-toggle",
                            value: "collapse-table" + table.name,
                            expression: "'collapse-table' + table.name",
                          },
                        ],
                        staticClass: "table__name table__name--margin",
                      },
                      [_vm._v(_vm._s(table.name))]
                    ),
                    _c(
                      "b-collapse",
                      { attrs: { id: `collapse-table${table.name}` } },
                      [
                        _c(
                          "div",
                          { staticClass: "columns__container" },
                          _vm._l(
                            table.schema.columns,
                            function (column, columnIndex) {
                              return _c(
                                "div",
                                {
                                  key: columnIndex,
                                  staticClass: "table__column",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        `${column.fieldName} (${column.dataType})`
                                      )
                                    ),
                                  ]),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ])
    : _c("div", { staticClass: "loading__bar" }, [_c("b-spinner")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }