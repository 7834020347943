<script>
export default {
  components: {},
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup() {},
};
</script>

<template>
  <div>
    <span>
      {{ data.formattedRow[data.column.field] }}
    </span>
  </div>
</template>

<style lang="scss"></style>
