<script>
import WorkspaceRow from '@/components/table-data/WorkspaceRow.vue'
import router from '@/router'
import store from '@/store'
import {
  CustomTable,
  PrimaryCreateButton,
  SearchInputField,
} from '@nodus/utilities-front'
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
} from 'vue'

export default {
  components: {
    PrimaryCreateButton,
    SearchInputField,
    CustomTable,
    WorkspaceRow,
  },
  setup() {
    const root = getCurrentInstance().proxy
    const allData = computed(() => store.getters['workspace/getData'])
    const canQuery = computed(() => store.getters['workspace/getCanQuery'])
    const { organizationId } = root.$route.params

    const isLoading = ref(false)

    onBeforeMount(() => {
      if (!organizationId) router.push('/organizations')
    })

    onMounted(async () => {
      isLoading.value = true
      if (organizationId)
        await store.dispatch('workspace/getData', { organizationId })
      else return
      isLoading.value = false
    })

    const newQuery = () => {
      router.push({
        name: 'biskql-new-query',
      })
    }

    return {
      allData,
      isLoading,
      newQuery,
      canQuery,
    }
  },
}
</script>

<template>
  <div id="content__wrapper" class="content__wrapper">
    <div class="table-list__header">
      <PrimaryCreateButton
        id="new-query"
        name="New Query"
        :can-add="canQuery"
        :is-loading="isLoading"
        :click-handler="newQuery"
      />
      <SearchInputField id="search-table" :data="allData" />
    </div>
    <div class="table__wrapper preview__table">
      <CustomTable
        type-of-table="workspace"
        :data="allData"
        :is-loading="isLoading"
        table-mode="local"
      >
        <template #row-data="{ data }">
          <WorkspaceRow :data="data" />
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/views/workspace.scss';
@import '@/assets/scss/views/preview.scss';
</style>
