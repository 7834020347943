export default {
  validate: {
    query: '',
    processingSize: '',
    valid: true,
    loading: false,
    error: {
      message: '',
      line: -1,
      column: -1,
      position: -1,
      block: {}
    },
    request: null
  },
  execute: {
    query: '',
    error: '',
    showResults: false,
    loading: false,
    request: null
  },
  table: {
    headers: [],
    rows: [],
    page: 0,
    perPage: 100,
    isLastPage: false
  }
}