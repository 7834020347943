<script>
import store from '@/store'
import { BCollapse, BSpinner, VBToggle } from 'bootstrap-vue'
import { computed } from 'vue'

export default {
  name: 'EditorSchema',
  props: {
    isLoading: {
      type: [Boolean],
    },
  },
  components: {
    BCollapse,
    BSpinner,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const schemaData = computed(() => store.getters['schema/getEditorSchemas'])
    const datasetName = computed(() => store.getters['schema/getDatasetName'])

    return {
      schemaData,
      datasetName,
    }
  },
}
</script>

<template>
  <div class="schema__container" v-if="!isLoading">
    <div class="container--relative">
      <img src="@/assets/svgs/table.svg" />
      <span v-b-toggle.collapse-all class="table__name table__name--margin">{{
        datasetName
      }}</span>
      <b-collapse visible id="collapse-all" class="tables__container">
        <div
          v-for="(table, index) in schemaData"
          :key="index"
          class="container--relative"
        >
          <img src="@/assets/svgs/table.svg" />
          <span
            v-b-toggle="'collapse-table' + table.name"
            class="table__name table__name--margin"
            >{{ table.name }}</span
          >
          <b-collapse class="" :id="`collapse-table${table.name}`">
            <div class="columns__container">
              <div
                class="table__column"
                v-for="(column, columnIndex) in table.schema.columns"
                :key="columnIndex"
              >
                <span>{{ `${column.fieldName} (${column.dataType})` }}</span>
              </div>
            </div>
          </b-collapse>
        </div>
      </b-collapse>
    </div>
  </div>
  <div v-else class="loading__bar">
    <b-spinner />
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/views/editor-panes/schema';
</style>
