var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "table-and-pagination__container" }, [
    _c(
      "div",
      { staticClass: "results__table-container" },
      [
        _c("VirtualList", {
          staticClass: "scrollable__area",
          attrs: {
            "data-key": "index",
            "data-sources": _vm.data.rows,
            "data-component": _vm.itemComponent,
            "extra-props": { resizedColumns: _vm.resizedColumns },
            "estimate-size": 38,
            "item-class": "table__row",
            "wrap-class": "table__body",
            "header-class": "thead",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "table__row" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row-number__index table__cell table__header",
                          },
                          [_vm._v("Row")]
                        ),
                        _vm._l(_vm.data.headers, function (header, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "table__cell table__header",
                              style: `min-width: ${
                                _vm.resizedColumns.find(
                                  (rc) => +rc.colIndex === index
                                )
                                  ? _vm.resizedColumns.find(
                                      (rc) => +rc.colIndex === index
                                    ).width
                                  : 180
                              }px`,
                            },
                            [
                              _c("span", [_vm._v(" " + _vm._s(header))]),
                              _c(
                                "div",
                                {
                                  staticClass: "resizer",
                                  attrs: { colIndex: index },
                                  on: {
                                    mousedown: function ($event) {
                                      return _vm.mouseDownHandler($event)
                                    },
                                    mouseup: function ($event) {
                                      return _vm.mouseUpHandler($event)
                                    },
                                  },
                                },
                                [_c("div", { staticClass: "divider" })]
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { staticClass: "table__pagination space-between" }, [
      _c(
        "div",
        { staticClass: "table__pagination-left" },
        [
          _c("span", { staticClass: "text-nowrap" }, [
            _vm._v(" " + _vm._s(_vm.$t("rowsPerPage")) + ": "),
          ]),
          _c("b-form-select", {
            staticClass: "mx-25 per-page__input",
            attrs: { id: "per-page-dropdown", options: ["50", "100", "200"] },
            on: { input: _vm.changePerPage },
            model: {
              value: _vm.data.perPage,
              callback: function ($$v) {
                _vm.$set(_vm.data, "perPage", $$v)
              },
              expression: "data.perPage",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "pagination__right-container" },
          [
            _c(
              "b-button",
              {
                staticClass: "navigate__button navigate__button-previous",
                attrs: {
                  id: "previous-page",
                  variant: "outline-secondary",
                  disabled: _vm.cantNavigate("previous"),
                },
                on: {
                  click: function ($event) {
                    return _vm.navigate("previous")
                  },
                },
              },
              [
                _c("feather-icon", {
                  staticClass: "pagination__icon",
                  attrs: { icon: "ChevronLeftIcon", size: "16" },
                }),
                _c(
                  "span",
                  {
                    staticClass: "pagination__text",
                    class: {
                      "pagination__text--disabled":
                        _vm.cantNavigate("previous"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("previous")))]
                ),
              ],
              1
            ),
            _c(
              "b-button",
              {
                staticClass: "navigate__button navigate__button-next",
                attrs: {
                  id: "next-page",
                  variant: "outline-secondary",
                  disabled: _vm.cantNavigate("next"),
                },
                on: {
                  click: function ($event) {
                    return _vm.navigate("next")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "pagination__text",
                    class: {
                      "pagination__text--disabled": _vm.cantNavigate("next"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("next")))]
                ),
                _c("feather-icon", {
                  staticClass: "pagination__icon",
                  attrs: { icon: "ChevronRightIcon", size: "16" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }