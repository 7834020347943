import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import { destructJSONObject } from '@nodus/utilities-front'
import {
  formatMetricsRequest,
  graphConfig,
  timePeriodPlaceholder,
  units,
} from './graphConfig'

export default {
  namespaced: true,
  state: {
    dashboardGraph: graphConfig,
    dashboardTable: {
      columns: [
        {
          label: 'Table',
          field: 'table',
          sortable: true,
          width: '50%',
        },
        {
          label: 'Processed',
          field: 'processed',
          sortable: true,
          width: '25%',
          type: 'number',
        },
        {
          label: 'Time Period',
          field: 'timePeriod',
          width: '25%',
          sortable: true,
        },
      ],
      originalRows: [],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
    },
    secondaryFilters: {
      table: {
        type: 'options',
        placeholder: 'Enter table name',
        searchTerm: '',
        options: [],
      },
      processed: { type: 'range', from: null, to: null, applied: false },
      timePeriod: {
        type: 'options',
        placeholder: 'Enter time period',
        searchTerm: '',
        options: [],
      },
    },
    allDashboardTables: [],
  },
  getters: {
    graph: (state) => state.dashboardGraph,
    table: (state) => state.dashboardTable,
    secondaryFilters: (state) => state.secondaryFilters,
    allTables: (state) => state.allDashboardTables,
  },
  actions: {
    async getTable(
      { commit },
      { organizationId, tables, unit, period, dateFrom, dateTo }
    ) {
      const queryParamString = formatMetricsRequest(
        tables,
        unit,
        period,
        dateFrom,
        dateTo
      )
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/metrics/table?${queryParamString}`
      )
      commit('SET_TABLE', destructJSONObject(response, 'metrics'))
      commit('SET_SECONDARY_FILTERS', {
        data: destructJSONObject(response, 'metrics'),
        period,
      })
    },
    async getGraph(
      { commit },
      { organizationId, tables, unit, period, dateFrom, dateTo }
    ) {
      commit('SET__GRAPH', { graph: [], unit, loading: true })
      const queryParamString = formatMetricsRequest(
        tables,
        unit,
        period,
        dateFrom,
        dateTo
      )
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/metrics/graph?${queryParamString}`
      )
      commit('SET__GRAPH', {
        graph: destructJSONObject(response, 'metrics'),
        unit,
      })
    },
    async getFile(
      _,
      { organizationId, tables, unit, period, dateFrom, dateTo, format }
    ) {
      const queryParamString = formatMetricsRequest(
        tables,
        unit,
        period,
        dateFrom,
        dateTo
      )
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/metrics/file?${queryParamString}&FileDownloadFormat=${format}`,
        { responseType: 'blob' }
      )

      return response.data
    },
    async getAllTables({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/tables`
      )
      commit('SET_ALL_TABLES', destructJSONObject(response, 'tables'))
    },
    filterRows({ commit }) {
      commit('FILTER_ROWS')
    },
  },
  mutations: {
    SET_TABLE(state, data) {
      state.dashboardTable.originalRows = data
      state.dashboardTable.rows = data
      state.dashboardTable.totalRecords = data.length
    },
    SET__GRAPH(state, { graph, unit, loading }) {
      const noDataText = 'No data have been added yet...'
      state.dashboardGraph.noData.text = loading ? 'Loading...' : noDataText
      state.dashboardGraph.title.text = `Processing (${units[unit]})`

      const series = graph.map((g) => g.processed)
      const xaxis = graph.map((g) => g.timePeriod)

      state.dashboardGraph.series[0].data =
        series.length === 1 ? [...series, ...series] : series
      state.dashboardGraph.xaxis.categories =
        series.length === 1 ? [...xaxis, ...xaxis] : xaxis
    },
    SET_SECONDARY_FILTERS(state, { data, period }) {
      const secondaryFilters = {
        table: {
          type: 'options',
          placeholder: 'Enter table name',
          searchTerm: '',
          options: [],
        },
        processed: { type: 'range', from: null, to: null, applied: false },
        timePeriod: {
          type: 'options',
          placeholder: `Enter ${timePeriodPlaceholder[period]}`,
          searchTerm: '',
          options: [],
        },
      }
      data.forEach((d) => {
        if (
          !secondaryFilters.table.options.some((table) => table.id === d.table)
        )
          secondaryFilters.table.options.push({ id: d.table, selected: false })
        if (
          !secondaryFilters.timePeriod.options.some(
            (timePeriod) => timePeriod.id === d.timePeriod
          )
        )
          secondaryFilters.timePeriod.options.push({
            id: d.timePeriod,
            selected: false,
          })
      })
      state.secondaryFilters = secondaryFilters
    },
    SET_ALL_TABLES(state, tables) {
      state.allDashboardTables = tables
    },
    FILTER_ROWS(state) {
      const {
        dashboardTable: { originalRows },
        secondaryFilters,
      } = state
      let filteredRows = originalRows

      Object.keys(secondaryFilters).forEach((filterKey) => {
        const filter = secondaryFilters[filterKey]
        if (filter.type === 'range') {
          filteredRows = filteredRows.filter((row) => {
            if (!filter.to) return row.processed >= +filter.from
            else if (!filter.from) return row.processed <= +filter.to
            else
              return (
                row.processed >= +filter.from && row.processed <= +filter.to
              )
          })
        } else {
          filteredRows = filteredRows.filter((row) => {
            if (filter.options.some((option) => option.selected))
              return filter.options.some(
                (option) => option.selected && option.id === row[filterKey]
              )
            else return true
          })
        }
      })

      state.dashboardTable.rows = filteredRows
      state.dashboardTable.totalRecords = filteredRows.length
    },
  },
}
