<template>
  <div class="filter__dropdown">
    <b-dropdown
      v-if="filter.type === 'range'"
      :id="`${id}-dropdown`"
      text="Dropdown Button"
      class="filter-dropdown__icon"
    >
      <template #button-content>
        <img :src="require('@/assets/svgs/filter.svg')">
      </template>
      <b-dropdown-text>
        <div class="range__filter">
          <b-form-input
            class="form__input range__input"
            placeholder="From"
            type="number"
            v-model="filter.from"
          />
          <span class="range__divider">-</span>
          <b-form-input
            class="form__input range__input"
            placeholder="To"
            type="number"
            v-model="filter.to"
          />
        </div>
        <span class="range__apply-filter" @click="applyRange">Apply</span>
      </b-dropdown-text>
    </b-dropdown>
    <b-dropdown
      v-else
      :id="`${id}-dropdown`"
      text="Dropdown Button"
      class="filter-dropdown__icon"
    >
      <template #button-content>
        <img :src="require('@/assets/svgs/filter.svg')">
      </template>
      <b-dropdown-text>
        <b-form-input
          class="form__input manual__input"
          :placeholder="filter.placeholder"
          type="text"
          v-model="filter.searchTerm"
        />
        <div class="actions__container">
          <span
            @click="
              filter.options.some((option) => !option.selected)
                ? selectAll()
                : {}
            "
            >Select All</span
          >
          <div class="dot"></div>
          <span @click="clearAll()">Clear</span>
        </div>
      </b-dropdown-text>
      <b-dropdown-group
        v-if="filter.options.length"
        class="dropdown__scrollable-area"
      >
        <b-dropdown-text
          v-for="option in filteredOptions"
          :key="option.id"
          @click="selectItem(option)"
        >
          <b-form-checkbox v-model="option.selected" />
          <span>{{ option.id }}</span>
        </b-dropdown-text>
      </b-dropdown-group>
      <div v-else class="filter__empty-state">
        <span>No filters to show...</span>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import {
BDropdown,
BDropdownGroup,
BDropdownText,
BFormCheckbox,
BFormInput,
} from "bootstrap-vue";
import {computed} from "vue";

export default {
  emits: ["apply-range"],
  props: {
    filter: {
      type: [Object],
    },
    id: {
      type: [String],
    },
    placeholder: {
      type: [String],
      default: "Search...",
    },
  },
  components: {
    BDropdown,
    BDropdownText,
    BDropdownGroup,
    BFormCheckbox,
    BFormInput,
  },
  setup(props, ctx) {
    const filteredOptions = computed(() =>
      props.filter.options.filter(
        (option) =>
          option.id
            .toString()
            .toLowerCase()
            .indexOf(props.filter.searchTerm.toLowerCase()) !== -1
      )
    );

    const addOption = (option) => {
      option.selected = true;
    };

    const removeOption = (option) => {
      option.selected = false;
    };

    const selectAll = () => {
      filteredOptions.value.forEach((option) => {
        option.selected = true;
      });
      ctx.emit("apply-filters");
    };

    const clearAll = () => {
      filteredOptions.value.forEach((option) => (option.selected = false));
      ctx.emit("apply-filters");
    };

    const selectItem = (option) => {
      !option.selected ? addOption(option) : removeOption(option);
      ctx.emit("apply-filters");
    };

    const applyRange = () => {
      if (!props.filter.to && !props.filter.from) {
        props.filter.applied = false;
      } else {
        props.filter.applied = true;
      }
      ctx.emit("apply-filters");
    };

    return {
      selectItem,
      selectAll,
      clearAll,
      filteredOptions,
      applyRange,
    };
  },
};
</script>

<style lang="scss">
.filter__header {
  display: flex;
  align-items: center;
}

.filter__dropdown {
  padding-left: 8px;
  z-index: 1;
  position: relative;

  .filter-dropdown__icon {
    width: 20px;
    height: 16px;
  }

  .btn {
    padding: 0 !important;
  }

  .btn-group {
    margin: 0 !important;
    vertical-align: text-top !important;
  }

  .btn-secondary {
    border-color: transparent !important;
    background-color: transparent !important;
    height: 16px;

    &::after {
      display: none;
    }
  }

  .b-dropdown-text {
    padding: 12px;
    &:hover {
      cursor: default;
    }
  }

  .dropdown__scrollable-area {
    overflow: auto;
    max-height: 200px;
    max-width: 280px;

    li {
      min-height: 36px;
    }

    .b-dropdown-text {
      font-size: 14px;
      font-weight: 400;
      color: #667c99;
      padding: 6px 14px;
      white-space: unset;
      display: flex;
      align-items: center;

      .custom-checkbox {
        min-height: fit-content;
      }

      span:first-child {
        padding-right: 6px;
      }

      span:last-child {
        max-width: 190px;
        overflow-wrap: break-word;
        margin-left: 8px;
      }

      &:hover {
        cursor: pointer;
        color: #1d79f2 !important;
        background: rgba(29, 121, 242, 0.12) !important;
      }
    }
  }

  .dropdown-menu {
    max-width: 260px;
    min-width: 260px;
    max-height: 300px;
    padding: 0;
  }

  .custom-checkbox.custom-control {
    padding-left: 20px;
    display: inline;
    pointer-events: none;
  }

  .range__filter {
    display: flex;

    .range__input {
      min-width: unset;
    }

    .range__divider {
      margin: 0 12px;
      height: 50%;
      width: 28px;
      color: #fff;
      border-bottom: 1px solid #a3b0c2;
    }
  }

  .range__apply-filter {
    color: #1d79f2;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    float: right;
    cursor: pointer;
  }
}

.actions__container {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  align-items: center;

  span {
    font-size: 12px;
    font-weight: 500;
    color: #1d79f2;
  }

  span:hover {
    cursor: pointer;
  }

  .dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    display: inline-block;
    background: #667c99;
    margin: 0 8px;
  }
}

.filter__empty-state {
  text-align: center;
  padding: 0 12px 8px;
  font-weight: 300;
  color: #667c99;
  cursor: default;
}
</style>
