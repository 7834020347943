<script>
import router from '@/router'
import store from '@/store'
import { DropdownActions, ReadMore } from '@nodus/utilities-front'
import { BButton } from 'bootstrap-vue'
import { computed } from 'vue'

export default {
  components: {
    BButton,
    ReadMore,
    DropdownActions,
  },
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup(props) {
    const canQuery = computed(() => store.getters['workspace/getCanQuery'])
    const canPreview = computed(() => store.getters['workspace/getCanPreview'])
    const todayDate = new Date()
    const month = todayDate.getUTCMonth() + 1
    const day = todayDate.getUTCDate()
    const year = todayDate.getUTCFullYear()

    const formatTemplateQuery = (row) => {
      const templateQuery = `SELECT  FROM ${row.name} WHERE PartitionDate = '${year}-${month}-${day}'`
      store.dispatch('workspace/setInitialQuery', templateQuery)
      router.push({
        name: 'biskql-new-query',
      })
    }

    const { name } = props.data.row

    const previewLocation = {
      name: 'biskql-preview',
      params: {
        tableName: name,
      },
    }

    const actionItems = [
      {
        id: 'preview-action',
        text: 'Preview',
        icon: 'IconparkPreviewOpen',
        disabled: !canPreview.value,
        to: previewLocation,
      },
      {
        id: 'schema-action',
        text: 'Schema',
        icon: 'Table',
        to: {
          name: 'biskql-schema',
          params: {
            tableName: name,
          },
        },
      },
    ]

    return {
      formatTemplateQuery,
      canQuery,
      actionItems,
      canPreview,
      previewLocation,
    }
  },
}
</script>

<template>
  <div>
    <div v-if="data.column.field === 'name'" class="name__wrapper">
      <ReadMore
        :text="data.row.name"
        :to="canPreview ? previewLocation : null"
      />
    </div>

    <div v-else-if="data.column.field === 'size'">
      {{ data.row.readSize }}
    </div>
    <div v-if="data.column.field === 'rows'" class="space-between">
      <span>{{ data.row.readRows }}</span>
      <div class="space-between">
        <b-button
          :disabled="!canQuery"
          id="new-query-item"
          class="new-query__button"
          @click="canQuery ? formatTemplateQuery(data.row) : {}"
        >
          <img src="@/assets/svgs/compose.svg" />
          <span>New Query</span>
        </b-button>
        <span class="workspace__actions">
          <DropdownActions id="table-actions" :items="actionItems" />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-query__button {
  background: #f5f9ff !important;
  padding: 6px 8px !important;
  margin-right: 18px;
  border: none !important;
  height: 32px !important;

  &:focus,
  &:active {
    background: #f5f9ff !important;
  }

  span {
    margin-left: 10px;
    font-size: 12px;
    color: #3a81f6 !important;
  }
}

.workspace__actions {
  .actions__dots {
    color: #667c99;
  }
}

.name__wrapper {
  cursor: pointer;
}
</style>
