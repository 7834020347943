<template>
  <div id="content__wrapper" class="content__wrapper new-query__wrapper">
    <div
      class="new-query__header"
      @click="
        $router.push({
          name: 'biskql-workspace'
        })
      "
    >
      <feather-icon
        icon="ChevronLeftIcon"
        size="16"
        class="pagination__icon"
      /><span class="new-query__header-text" id="go-back">Go Back</span>
    </div>
    <div id="editor" class="workspace__new-query">
      <splitpanes ref="splitRef" class="default-theme">
        <!-- Schema Pane -->
        <pane
          class="workspace__custom-pane schema__pane"
          :class="{ 'schema__pane--collapsed': schemaCollapsed }"
          min-size="15"
          size="25"
        >
          <div class="schema__pane__content">
            <div v-if="schemaCollapsed" class="content_folder-icon">
              <img src="@/assets/svgs/folder.svg" />
            </div>
            <Schema
              :hidden="schemaCollapsed"
              :organization="organizationId"
              :is-loading="isSchemaLoading"
            />
          </div>
          <div class="schema__pane__footer">
            <div
              class="schema__pane__footer_content"
              @click="!isSchemaLoading ? toggleSchemaCollapse() : {}"
            >
              <img
                src="@/assets/svgs/collapse.svg"
                :class="{ 'collapse_icon--flipped': schemaCollapsed }"
              />
              <span
                v-if="!schemaCollapsed"
                id="collapse-menu"
                class="ml-50 unselectable-text"
                >Collapse menu</span
              >
            </div>
          </div>
        </pane>
        <pane min-size="60">
          <b-tabs ref="editorTabs" align="left">
            <template slot="tabs-end">
              <div class="new-button__container">
                <div class="new-button__content">
                  <span
                    class="unselectable-text"
                    id="new-query"
                    @click="newTab()"
                    >+ New Query</span
                  >
                </div>
              </div>
            </template>
            <b-tab
              v-for="(editor, index) in allEditorTabs"
              :id="`editor-tab_` + editor.id"
              :key="editor.id"
            >
              <template slot="title">
                {{ `Editor ${editor.id}` }}
                <img
                  v-if="allEditorTabs.length > 1"
                  src="@/assets/svgs/close.svg"
                  class="tab-close"
                  @click="closeTab(index)"
                />
              </template>
              <splitpanes horizontal>
                <pane class="editor__pane">
                  <Editor
                    :index="index"
                    :content="editor.id === 1 ? initialQuery : ''"
                    :data="editor"
                    @run-query="executeQuery"
                    @cancel-query="cancel('execute', editor)"
                    @validate-query="handleValidation"
                  />
                </pane>
                <pane
                  v-if="editor.execute.showResults"
                  class="workspace__custom-pane query-results__pane"
                  size="50"
                >
                  <div class="query-results__pane__header">Query results</div>
                  <div class="query-results__pane__content">
                    <Results
                      :data="editor"
                      :index="index"
                      @update-table="executeQuery(editor.execute.query, index)"
                    />
                  </div>
                </pane>
              </splitpanes>
            </b-tab>
          </b-tabs>
        </pane>
      </splitpanes>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import { BTab, BTabs } from 'bootstrap-vue'
import * as CodeMirror from 'codemirror'
import { Pane, Splitpanes } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref
} from 'vue'
import Editor from './editor-panes/Editor.vue'
import Results from './editor-panes/Results.vue'
import Schema from './editor-panes/Schema.vue'

export default {
  name: 'NewQuery',
  components: {
    Splitpanes,
    Pane,
    Editor,
    Schema,
    Results,
    BTabs,
    BTab
  },
  setup() {
    const root = getCurrentInstance().proxy
    const schemaCollapsed = ref(false)
    const initialQuery = computed(
      () => store.getters['workspace/getInitialQuery']
    )
    const { organizationId } = root.$route.params
    const isSchemaLoading = ref(false)
    const allEditorTabs = computed(
      () => store.getters['results/getAllEditorTabs']
    )

    const editorTabs = ref('')
    const splitRef = ref('')

    const getCancellationToken = () => {
      const { CancelToken } = axios
      return CancelToken.source()
    }

    const clearPreviousRequest = (eventType, editor) => {
      editor[eventType].request = null
    }

    const cancel = (eventType, editor) => {
      editor[eventType].request.cancel()
      clearPreviousRequest(eventType, editor)

      if (eventType === 'execute') editor.execute.loading = false
    }

    onBeforeMount(() => {
      if (!organizationId) router.push('/organizations')
    })

    onMounted(async () => {
      isSchemaLoading.value = true
      store.commit('results/ADD_EDITOR_TAB', 1)
      await store
        .dispatch('schema/getEditorSchemas', {
          organizationId
        })
        .then(() => (isSchemaLoading.value = false))
    })

    const newTab = () => {
      const value = allEditorTabs.value[allEditorTabs.value.length - 1].id
      store.commit('results/ADD_EDITOR_TAB', value + 1)
      setTimeout(() => {
        editorTabs.value.lastTab()
      }, 50)
    }

    const closeTab = (index) => {
      store.commit('results/REMOVE_EDITOR_TAB', index)
    }

    const toggleSchemaCollapse = () => {
      const contentWidth = document.getElementById('editor').clientWidth
      const schemaSize = (56 * 100) / contentWidth

      if (!schemaCollapsed.value) {
        splitRef.value.panes[0].min = schemaSize
        splitRef.value.panes[1].size = 100 - schemaSize
        splitRef.value.panes[1].min = 100 - schemaSize
      } else {
        splitRef.value.panes[0].min = 15
        splitRef.value.panes[1].min = 60
        if (splitRef.value.panes[0].size < 15) splitRef.value.panes[0].size = 15
      }

      schemaCollapsed.value = !schemaCollapsed.value
    }

    const executeQuery = async (query, editorIndex) => {
      const source = getCancellationToken()
      const editor = allEditorTabs.value[editorIndex]
      editor.execute.request = { cancel: source.cancel }
      editor.execute.loading = true
      editor.execute.query = query
      editor.execute.showResults = true
      await store
        .dispatch('results/executeQuery', {
          organizationId,
          query,
          editor: editorIndex,
          cancelToken: source.token
        })
        .then(() => clearPreviousRequest('execute', editor))
      editor.execute.loading = false
    }

    const formatQuery = (query) => query.replace(/^(\\n)+|(\\n)+$/, '')

    const removeGutterMarks = (editorInstance) => {
      const lines = editorInstance.lineCount()
      for (let i = 0; i <= lines; i++) {
        editorInstance.removeLineClass(i, 'gutter', 'query__syntax-error')
      }
    }

    const validateQuery = async (
      query,
      _,
      editorInstance,
      eventType = 'change'
    ) => {
      const editor = editorInstance.options.editorData.data
      editor.validate.loading = true
      const formattedQuery = formatQuery(query.trim())

      if (editor.validate.request) cancel('validate', editor)

      if (!formattedQuery) {
        if (eventType === 'change') removeGutterMarks(editorInstance)
        editor.validate.loading = false
        editor.validate.query = formattedQuery
        editor.validate.error.block = {}
        return editor.validate.error.block
      }

      if (formattedQuery === editor.validate.query) {
        editor.validate.loading = false
        if (!editor.validate.valid && editor.validate.query === query)
          return editor.validate.error.block

        if (eventType === 'change') removeGutterMarks(editorInstance)
        editor.validate.error.block = {}
        return editor.validate.error.block
      }

      const source = getCancellationToken()
      editor.validate.request = { cancel: source.cancel }
      let canceled = false
      await store
        .dispatch('results/validateQuery', {
          organizationId,
          query,
          editor: editorInstance.options.editorData.index,
          cancelToken: source.token
        })
        .then(() => clearPreviousRequest('validate', editor))
        .catch((err) => {
          if (err.name === 'CanceledError') canceled = true
        })

      if (canceled) return editor.validate.error.block

      if (eventType === 'change') removeGutterMarks(editorInstance)

      editor.validate.loading = false
      editor.validate.error.block = {}
      editor.validate.query = formattedQuery
      if (editor.validate.valid) return {}
      else {
        const { line, column } = editor.validate.error
        if (column === -1 || line === -1) return {}

        let word = editorInstance.findWordAt({
          line: line - 1,
          ch: column - 1
        })
        if (eventType === 'change')
          editorInstance.addLineClass(line - 1, 'gutter', 'query__syntax-error')
        editor.validate.error.block = [
          {
            message: editor.validate.error.message,
            severity: 'error',
            from: CodeMirror.Pos(word.anchor.line, word.anchor.ch),
            to: CodeMirror.Pos(word.head.line, word.head.ch)
          }
        ]

        return editor.validate.error.block
      }
    }

    CodeMirror.registerHelper('lint', 'sql', validateQuery)

    const handleValidation = (
      eventType,
      selectedText,
      selection,
      editorInstance
    ) => {
      if (eventType === 'change') editorInstance.performLint()
      else validateQuery(selectedText, selection, editorInstance, 'select')
    }

    onBeforeUnmount(() => {
      store.dispatch('workspace/setInitialQuery', '')
      store.commit('results/RESET_EDITORS_STATE')
    })

    return {
      newTab,
      closeTab,
      schemaCollapsed,
      toggleSchemaCollapse,
      initialQuery,
      isSchemaLoading,
      executeQuery,
      allEditorTabs,
      handleValidation,
      cancel,
      organizationId,
      editorTabs,
      splitRef
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/views/newquery';
</style>
