import Dashboard from '@/views/Dashboard.vue'
import NewQuery from '@/views/NewQuery.vue'
import Workspace from '@/views/Workspace.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/bisko/:organizationId/biskql',
    redirect: '/bisko/:organizationId/biskql/workspace'
  },
  {
    path: '/bisko/:organizationId/biskql/workspace',
    name: 'biskql-workspace',
    component: Workspace,
    meta: {
      navActiveLink: 'biskql-workspace',
      pageTitle: 'Workspace',
      breadcrumb: [
        {
          text: 'Biskql'
        },
        {
          text: 'Workspace',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/biskql/:tableName/preview',
    name: 'biskql-preview',
    component: () => import('@/views/Preview.vue'),
    meta: {
      navActiveLink: 'biskql-workspace',
      pageTitle: 'Preview',
      breadcrumb: [
        {
          text: 'Biskql'
        },
        {
          text: 'Workspace',
          to: '/workspace'
        },
        {
          text: 'Preview',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.pageTitle = to.params.tableName
      to.meta.breadcrumb[2].text = to.params.tableName
      next()
    }
  },
  {
    path: '/bisko/:organizationId/biskql/:tableName/schema',
    name: 'biskql-schema',
    component: () => import('@/views/Schemas.vue'),
    meta: {
      navActiveLink: 'biskql-workspace',
      pageTitle: 'Schema',
      breadcrumb: [
        {
          text: 'Biskql'
        },
        {
          text: 'Workspace',
          to: '/workspace'
        },
        {
          text: 'Table Schema',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.pageTitle = to.params.tableName
      next()
    }
  },
  {
    path: '/bisko/:organizationId/biskql/new-query',
    name: 'biskql-new-query',
    component: NewQuery,
    meta: {
      navActiveLink: 'biskql-workspace',
      pageTitle: 'New Query',
      breadcrumb: [
        {
          text: 'Biskql'
        },
        {
          text: 'Workspace',
          to: '/workspace'
        },
        {
          text: 'New Query',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/biskql/dashboard',
    name: 'biskql-dashboard',
    component: Dashboard,
    meta: {
      navActiveLink: 'biskql-dashboard',
      pageTitle: 'Dashboard',
      breadcrumb: [
        {
          text: 'Biskql'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/bisko/:organizationId/biskql/:pathMatch(.*)*',
    redirect: '/error/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
