var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content__wrapper", attrs: { id: "content__wrapper" } },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "table__wrapper preview__table not-sortable__table" },
        [
          _c("CustomTable", {
            attrs: {
              "type-of-table": "preview",
              data: _vm.previewData,
              "is-loading": _vm.isLoading,
              "is-sort-enabled": false,
            },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("PreviewRow", { attrs: { data: data } })]
                },
              },
              {
                key: "column-data",
                fn: function ({ data }) {
                  return [
                    _c("ResizableColumn", {
                      attrs: { content: data.column.label },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "preview__footer",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "biskql-workspace",
              })
            },
          },
        },
        [
          _c("feather-icon", {
            staticClass: "pagination__icon",
            attrs: { icon: "ChevronLeftIcon", size: "16" },
          }),
          _c(
            "span",
            {
              staticClass: "preview__footer-text",
              attrs: { id: "back-button" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "preview__header" }, [
      _c("span", { staticClass: "preview__text-information" }, [
        _vm._v("Preview latest 100 events collected"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }