var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content__wrapper", attrs: { id: "content__wrapper" } },
    [
      _c(
        "div",
        { staticClass: "table-list__header" },
        [
          _c("PrimaryCreateButton", {
            attrs: {
              id: "new-query",
              name: "New Query",
              "can-add": _vm.canQuery,
              "is-loading": _vm.isLoading,
              "click-handler": _vm.newQuery,
            },
          }),
          _c("SearchInputField", {
            attrs: { id: "search-table", data: _vm.allData },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table__wrapper preview__table" },
        [
          _c("CustomTable", {
            attrs: {
              "type-of-table": "workspace",
              data: _vm.allData,
              "is-loading": _vm.isLoading,
              "table-mode": "local",
            },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("WorkspaceRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }