import router from '@/router'
import { getUserData } from '@nodus/authentication'
import axios from 'axios'

const axiosIns = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${getUserData()?.access_token}`
  }
})

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.toJSON()
    if (status) router.push(`/error/${status}`)
    else router.push('/error/500')
  }
)

export default axiosIns
