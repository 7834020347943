<script>
import SchemaRow from '@/components/table-data/SchemaRow.vue'
import router from '@/router'
import store from '@/store'
import { CustomTable, SearchInputField } from '@nodus/utilities-front'
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
} from 'vue'

export default {
  components: {
    CustomTable,
    SearchInputField,
    SchemaRow,
  },
  setup() {
    const root = getCurrentInstance().proxy
    const { organizationId, tableName } = root.$route.params

    const allData = computed(() => store.getters['schema/getData'])

    const isLoading = ref(false)

    onBeforeMount(() => {
      if (!organizationId) router.push('/organizations')
    })

    onMounted(async () => {
      isLoading.value = true
      await store.dispatch('schema/getSchemas', {
        organizationId,
        tableName,
      })
      isLoading.value = false
    })

    const getData = async () => {
      isLoading.value = true
      await store.dispatch('schema/getSchemas', {
        organizationId,
        tableName,
      })
      isLoading.value = false
    }

    return {
      allData,
      getData,
      isLoading,
    }
  },
}
</script>

<template>
  <div id="content__wrapper" class="content__wrapper">
    <div class="schema__header-container">
      <SearchInputField id="search-schema" :data="allData" />
    </div>
    <div class="table__wrapper not-sortable__table preview__table">
      <CustomTable
        type-of-table="schema"
        :data="allData"
        :is-loading="isLoading"
        :is-sort-enabled="false"
        table-mode="local"
      >
        <template #row-data="{ data }">
          <SchemaRow :data="data" />
        </template>
      </CustomTable>
    </div>
    <div
      class="schemas__footer"
      @click="
        $router.push({
          name: 'biskql-workspace',
        })
      "
    >
      <feather-icon
        icon="ChevronLeftIcon"
        size="16"
        class="pagination__icon"
      /><span id="back-button" class="schemas__footer-text">Back</span>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/views/schemas.scss';
@import '@/assets/scss/views/preview.scss';
</style>
