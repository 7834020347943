import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import editorConfig from './editorConfig'

export default {
  namespaced: true,
  state: {
    editorTabs: [],
  },
  getters: {
    getAllEditorTabs: (state) => state.editorTabs,
  },
  actions: {
    async executeQuery(
      { commit, state },
      { organizationId, query, editor, cancelToken }
    ) {
      await axiosIns
        .post(
          `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/commands/executequery`,
          {
            query,
            page: state.editorTabs[editor].table.page,
            take: state.editorTabs[editor].table.perPage,
          },
          { cancelToken }
        )
        .then((response) => {
          commit('SET_RESULTS_HEADERS', {
            headers: response.data.header,
            editor,
          })
          commit('SET_RESULTS_ROWS', {
            rows: response.data.dynamicData,
            editor,
          })
          commit('SET_QUERY_ERROR', {
            error: response.data.errorDetails,
            editor,
          })
          commit('SET_LAST_PAGE', {
            isLastPage: response.data.isLastPage,
            editor,
          })
        })
        .catch((error) => {
          if (error.name === 'CanceledError')
            commit('SET_QUERY_ERROR', {
              error: 'The request was canceled',
              editor,
            })
          else
            commit('SET_QUERY_ERROR', {
              error: `Error: ${error.name}: ${error.message}`,
              editor,
            })
        })
    },
    async validateQuery(
      { commit },
      { organizationId, query, editor, cancelToken }
    ) {
      await axiosIns
        .post(
          `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/commands/validatequery`,
          {
            query,
          },
          { cancelToken }
        )
        .then((response) => {
          commit('SET_QUERY_VALIDATION', { validation: response.data, editor })
        })
    },
  },
  mutations: {
    SET_RESULTS_HEADERS(state, data) {
      state.editorTabs[data.editor].table.headers = data.headers ?? []
    },
    SET_RESULTS_ROWS(state, data) {
      const { page, perPage } = state.editorTabs[data.editor].table
      let rows = []
      for (let i = 0; i < data.rows.length; i++) {
        rows.push({
          index: page * perPage + i + 1,
          data: data.rows[i],
        })
      }

      state.editorTabs[data.editor].table.rows = rows
    },
    SET_QUERY_ERROR(state, data) {
      state.editorTabs[data.editor].execute.error = data.error
    },
    SET_LAST_PAGE(state, data) {
      state.editorTabs[data.editor].table.isLastPage = data.isLastPage
    },
    SET_QUERY_VALIDATION(state, { validation, editor }) {
      let { line, column, position } = validation.errorInternalDetail || {
        line: -1,
        column: -1,
        position: -1,
      }
      if (line === -1 && position !== -1) {
        line = 1
        column = position
      }

      state.editorTabs[editor].validate.valid = validation.isQueryValid
      state.editorTabs[editor].validate.error = {
        message: validation.error || validation.errorDetails,
        line,
        column,
        position,
        block: {},
      }
      state.editorTabs[editor].validate.processingSize =
        validation.queryDetails?.readMegaBytesApprox || '50 MiB'
    },
    ADD_EDITOR_TAB(state, index) {
      state.editorTabs.push({
        id: index,
        ...JSON.parse(JSON.stringify(editorConfig)),
      })
    },
    REMOVE_EDITOR_TAB(state, index) {
      state.editorTabs.splice(index, 1)
    },
    RESET_EDITORS_STATE(state) {
      state.editorTabs = []
    },
  },
}
