var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content__wrapper", attrs: { id: "content__wrapper" } },
    [
      _c(
        "div",
        { staticClass: "schema__header-container" },
        [
          _c("SearchInputField", {
            attrs: { id: "search-schema", data: _vm.allData },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table__wrapper not-sortable__table preview__table" },
        [
          _c("CustomTable", {
            attrs: {
              "type-of-table": "schema",
              data: _vm.allData,
              "is-loading": _vm.isLoading,
              "is-sort-enabled": false,
              "table-mode": "local",
            },
            scopedSlots: _vm._u([
              {
                key: "row-data",
                fn: function ({ data }) {
                  return [_c("SchemaRow", { attrs: { data: data } })]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "schemas__footer",
          on: {
            click: function ($event) {
              return _vm.$router.push({
                name: "biskql-workspace",
              })
            },
          },
        },
        [
          _c("feather-icon", {
            staticClass: "pagination__icon",
            attrs: { icon: "ChevronLeftIcon", size: "16" },
          }),
          _c(
            "span",
            {
              staticClass: "schemas__footer-text",
              attrs: { id: "back-button" },
            },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }