var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dashboard__wrapper content__wrapper",
      attrs: { id: "content__wrapper" },
    },
    [
      _c("div", { staticClass: "dashboard__header" }, [
        _c("div", { staticClass: "dashboard__header--left" }, [
          _c(
            "div",
            { staticClass: "form__input" },
            [
              _c("v-select", {
                staticClass: "multiple__select",
                attrs: {
                  id: "tables-select",
                  label: "name",
                  placeholder: "All tables",
                  options: _vm.primaryFilters.options.tables,
                  multiple: "",
                  "deselect-from-dropdown": true,
                  "close-on-select": false,
                },
                on: {
                  input: function ($event) {
                    return _vm.updateApi()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selected-option",
                    fn: function (option) {
                      return [
                        _vm.primaryFilters.selected.table[0].name ===
                        option.name
                          ? _c("span", [
                              _vm._v(" " + _vm._s(option.name) + " "),
                            ])
                          : _vm.primaryFilters.selected.table[1].name ===
                            option.name
                          ? _c(
                              "span",
                              { attrs: { id: "more-options" } },
                              [
                                _vm._v(
                                  " (+ " +
                                    _vm._s(
                                      _vm.primaryFilters.selected.table.length -
                                        1
                                    ) +
                                    " others) "
                                ),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "more-options",
                                      triggers: "hover",
                                      placement: "right",
                                    },
                                  },
                                  _vm._l(
                                    _vm.primaryFilters.selected.table,
                                    function (item, index) {
                                      return _c("div", { key: index }, [
                                        index > 0
                                          ? _c("span", [
                                              _vm._v(_vm._s(item.name)),
                                            ])
                                          : _vm._e(),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.primaryFilters.selected.table,
                  callback: function ($$v) {
                    _vm.$set(_vm.primaryFilters.selected, "table", $$v)
                  },
                  expression: "primaryFilters.selected.table",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form__input" },
            [
              _c("v-select", {
                attrs: {
                  id: "size-select",
                  label: "unit",
                  placeholder: "Select size",
                  options: _vm.primaryFilters.options.units,
                  clearable: false,
                  searchable: false,
                },
                on: {
                  input: function ($event) {
                    return _vm.updateApi()
                  },
                },
                model: {
                  value: _vm.primaryFilters.selected.unit,
                  callback: function ($$v) {
                    _vm.$set(_vm.primaryFilters.selected, "unit", $$v)
                  },
                  expression: "primaryFilters.selected.unit",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form__input" },
            [
              _c("v-select", {
                attrs: {
                  id: "frequency-select",
                  label: "period",
                  placeholder: "Select frequency",
                  options: _vm.primaryFilters.options.periodicity,
                  clearable: false,
                  searchable: false,
                },
                on: { input: _vm.validateWeeklyRange },
                model: {
                  value: _vm.primaryFilters.selected.period,
                  callback: function ($$v) {
                    _vm.$set(_vm.primaryFilters.selected, "period", $$v)
                  },
                  expression: "primaryFilters.selected.period",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "dashboard__header--right" }, [
          _c("span", [_vm._v("Show data per: ")]),
          _c(
            "div",
            {
              staticClass: "form__input date-range__input",
              class: { "date-range__invalid": !!_vm.dateRangeError.length },
            },
            [
              _c("flat-pickr", {
                ref: "flatpickr",
                staticClass: "form-control date",
                attrs: {
                  config: _vm.datepickerConfig,
                  id: "select-datepicker",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
              _c(
                "span",
                {
                  staticClass: "calendar-toggle",
                  on: {
                    click: function ($event) {
                      return _vm.toggleCalendar()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "18",
                        height: "18",
                        viewBox: "0 0 18 18",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2.5498 5.88555C2.5498 4.80859 3.42285 3.93555 4.4998 3.93555H13.4998C14.5768 3.93555 15.4498 4.80859 15.4498 5.88555V13.5009C15.4498 14.5779 14.5768 15.4509 13.4998 15.4509H4.4998C3.42285 15.4509 2.5498 14.5779 2.5498 13.5009V5.88555ZM4.4998 4.83555C3.91991 4.83555 3.4498 5.30565 3.4498 5.88555V13.5009C3.4498 14.0808 3.91991 14.5509 4.4998 14.5509H13.4998C14.0797 14.5509 14.5498 14.0808 14.5498 13.5009V5.88555C14.5498 5.30565 14.0797 4.83555 13.4998 4.83555H4.4998Z",
                          fill: "#7483A1",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M2.5498 7.61602C2.5498 7.36749 2.75128 7.16602 2.9998 7.16602H14.9998C15.2483 7.16602 15.4498 7.36749 15.4498 7.61602C15.4498 7.86454 15.2483 8.06602 14.9998 8.06602H2.9998C2.75128 8.06602 2.5498 7.86454 2.5498 7.61602Z",
                          fill: "#7483A1",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M5.76885 2.55078C6.01738 2.55078 6.21885 2.75225 6.21885 3.00078V5.77001C6.21885 6.01854 6.01738 6.22001 5.76885 6.22001C5.52032 6.22001 5.31885 6.01854 5.31885 5.77001V3.00078C5.31885 2.75225 5.52032 2.55078 5.76885 2.55078Z",
                          fill: "#7483A1",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M12.2308 2.55078C12.4793 2.55078 12.6808 2.75225 12.6808 3.00078V5.77001C12.6808 6.01854 12.4793 6.22001 12.2308 6.22001C11.9822 6.22001 11.7808 6.01854 11.7808 5.77001V3.00078C11.7808 2.75225 11.9822 2.55078 12.2308 2.55078Z",
                          fill: "#7483A1",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          "fill-rule": "evenodd",
                          "clip-rule": "evenodd",
                          d: "M6.23178 10.1552C6.23178 10.5375 5.92183 10.8475 5.53948 10.8475C5.15712 10.8475 4.84717 10.5375 4.84717 10.1552C4.84717 9.77285 5.15712 9.46289 5.53948 9.46289C5.92183 9.46289 6.23178 9.77285 6.23178 10.1552ZM7.84762 13.1552C8.22997 13.1552 8.53993 12.8453 8.53993 12.4629C8.53993 12.0806 8.22997 11.7706 7.84762 11.7706C7.46527 11.7706 7.15531 12.0806 7.15531 12.4629C7.15531 12.8453 7.46527 13.1552 7.84762 13.1552ZM5.53948 13.1552C5.92183 13.1552 6.23178 12.8453 6.23178 12.4629C6.23178 12.0806 5.92183 11.7706 5.53948 11.7706C5.15712 11.7706 4.84717 12.0806 4.84717 12.4629C4.84717 12.8453 5.15712 13.1552 5.53948 13.1552ZM10.8476 12.4629C10.8476 12.8453 10.5377 13.1552 10.1553 13.1552C9.77296 13.1552 9.463 12.8453 9.463 12.4629C9.463 12.0806 9.77296 11.7706 10.1553 11.7706C10.5377 11.7706 10.8476 12.0806 10.8476 12.4629ZM12.463 13.1552C12.8454 13.1552 13.1553 12.8453 13.1553 12.4629C13.1553 12.0806 12.8454 11.7706 12.463 11.7706C12.0807 11.7706 11.7707 12.0806 11.7707 12.4629C11.7707 12.8453 12.0807 13.1552 12.463 13.1552ZM7.84807 10.8475C8.23042 10.8475 8.54038 10.5375 8.54038 10.1552C8.54038 9.77285 8.23042 9.46289 7.84807 9.46289C7.46572 9.46289 7.15576 9.77285 7.15576 10.1552C7.15576 10.5375 7.46572 10.8475 7.84807 10.8475ZM10.8475 10.1552C10.8475 10.5375 10.5375 10.8475 10.1552 10.8475C9.77285 10.8475 9.46289 10.5375 9.46289 10.1552C9.46289 9.77285 9.77285 9.46289 10.1552 9.46289C10.5375 9.46289 10.8475 9.77285 10.8475 10.1552ZM12.4623 10.8475C12.8447 10.8475 13.1546 10.5375 13.1546 10.1552C13.1546 9.77285 12.8447 9.46289 12.4623 9.46289C12.08 9.46289 11.77 9.77285 11.77 10.1552C11.77 10.5375 12.08 10.8475 12.4623 10.8475Z",
                          fill: "#7483A1",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _vm.dateRangeError.length
                ? _c("span", { staticClass: "date-range__error" }, [
                    _vm._v(" " + _vm._s(_vm.dateRangeError) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "dashboard__graph" }, [
        _c(
          "div",
          { staticClass: "graph__wrapper" },
          [
            _c("VueApexCharts", {
              key: _vm.graphKey,
              attrs: {
                options: _vm.dashboardGraph,
                series: _vm.dashboardGraph.series,
                height: "250",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "dashboard__table" }, [
        _c("div", { staticClass: "dashboard__table__header" }, [
          _c("div", { staticClass: "filter__tags" }, [
            _vm.filterSelectedCount("table")
              ? _c("div", { staticClass: "filter-tag__table" }, [
                  _c("span", [
                    _vm._v(
                      "Table (" +
                        _vm._s(_vm.filterSelectedCount("table")) +
                        ") "
                    ),
                  ]),
                  _c("img", {
                    staticClass: "filter-tag__close",
                    attrs: { src: require("@/assets/svgs/close.svg") },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter("table")
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.columnFilters.processed.applied
              ? _c("div", { staticClass: "filter-tag__processed" }, [
                  _c("span", [_vm._v("Processed")]),
                  _c("img", {
                    staticClass: "filter-tag__close",
                    attrs: { src: require("@/assets/svgs/close.svg") },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter("processed")
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.filterSelectedCount("timePeriod")
              ? _c("div", { staticClass: "filter-tag__time-period" }, [
                  _c("span", [
                    _vm._v(
                      "Time Period (" +
                        _vm._s(_vm.filterSelectedCount("timePeriod")) +
                        ") "
                    ),
                  ]),
                  _c("img", {
                    staticClass: "filter-tag__close",
                    attrs: { src: require("@/assets/svgs/close.svg") },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilter("timePeriod")
                      },
                    },
                  }),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "export-data" },
            [
              _c(
                "b-dropdown",
                {
                  attrs: {
                    variant: "light",
                    size: "sm",
                    disabled:
                      _vm.isLoading ||
                      _vm.exporting ||
                      _vm.dashboardTable.totalRecords < 1,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "12",
                                height: "14",
                                viewBox: "0 0 12 14",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  "clip-rule": "evenodd",
                                  d: "M6.35773 0.549805L10.4785 4.67055V6.9998C10.4785 7.24833 10.277 7.44981 10.0285 7.44981C9.77995 7.44981 9.57847 7.24833 9.57847 6.9998V5.30695H7.67133C6.59438 5.30695 5.72133 4.4339 5.72133 3.35695V1.4498H2.0999C1.52 1.4498 1.0499 1.91991 1.0499 2.4998V11.4998C1.0499 12.0797 1.52 12.5498 2.0999 12.5498H10.0285C10.277 12.5498 10.4785 12.7513 10.4785 12.9998C10.4785 13.2483 10.277 13.4498 10.0285 13.4498H2.0999C1.02295 13.4498 0.149902 12.5768 0.149902 11.4998V2.4998C0.149902 1.42285 1.02295 0.549805 2.0999 0.549805H6.35773ZM6.62133 2.0862V3.35695C6.62133 3.93685 7.09143 4.40695 7.67133 4.40695H8.94208L6.62133 2.0862Z",
                                },
                              }),
                              _c("path", {
                                attrs: {
                                  d: "M4.86419 9.99981C4.86419 9.75128 5.06566 9.5498 5.31419 9.5498H10.4733L10.0826 8.9637C9.94475 8.75691 10.0006 8.47752 10.2074 8.33966C10.4142 8.2018 10.6936 8.25768 10.8314 8.46447L11.6847 9.74439C11.6875 9.74844 11.6903 9.75254 11.6929 9.75669L11.855 9.9998L11.6931 10.2427C11.6903 10.247 11.6875 10.2512 11.6846 10.2554L10.8314 11.5351C10.6936 11.7419 10.4142 11.7978 10.2074 11.6599C10.0006 11.5221 9.94475 11.2427 10.0826 11.0359L10.4733 10.4498H5.31419C5.06566 10.4498 4.86419 10.2483 4.86419 9.99981Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "export-xlsx" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(_vm.exportTypes.xlsx)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "14",
                            height: "13",
                            viewBox: "0 0 14 13",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z",
                              fill: "#667C99",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              d: "M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z",
                              fill: "#667C99",
                            },
                          }),
                        ]
                      ),
                      _c("span", [_vm._v("Export to XLSX")]),
                    ]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: { id: "export-csv" },
                      on: {
                        click: function ($event) {
                          return _vm.downloadFile(_vm.exportTypes.csv)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "14",
                            height: "13",
                            viewBox: "0 0 14 13",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z",
                              fill: "#667C99",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              d: "M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z",
                              fill: "#667C99",
                            },
                          }),
                        ]
                      ),
                      _c("span", [_vm._v("Export to CSV")]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "table__wrapper dashboard__table" },
          [
            _c("CustomTable", {
              attrs: {
                "type-of-table": "dashboard",
                data: _vm.dashboardTable,
                "column-filters": _vm.columnFilters,
                "is-pagination-enabled": true,
                "is-loading": _vm.isLoading,
                "table-mode": "local",
              },
              scopedSlots: _vm._u([
                {
                  key: "row-data",
                  fn: function ({ data }) {
                    return [_c("DashboardRow", { attrs: { data: data } })]
                  },
                },
                {
                  key: "column-data",
                  fn: function ({ data }) {
                    return [
                      _vm.columnFilters[data.column.field]
                        ? _c(
                            "span",
                            { staticClass: "filter__header" },
                            [
                              _vm._v(" " + _vm._s(data.column.label) + " "),
                              _c("FilterDropdown", {
                                attrs: {
                                  id: `${data.column.field}-filter`,
                                  filter: _vm.columnFilters[data.column.field],
                                },
                                on: { "apply-filters": _vm.applyFilters },
                              }),
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(" " + _vm._s(data.column.label) + " "),
                          ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }