import axiosIns from '@/libs/axios'
import { API_GATEWAY_URL } from '@/utils/constants'
import { destructJSONObject } from '@nodus/utilities-front'

export default {
  namespaced: true,
  state: {
    table: {
      pageLength: 10,
      columns: [
        {
          label: 'Field name',
          field: 'fieldName',
          width: '20%',
        },
        {
          label: 'Type',
          field: 'dataType',
          width: '20%',
        },
        {
          label: 'Mode',
          field: 'mode',
          width: '20%',
        },
        {
          label: 'Description',
          field: 'description',
          width: '40%',
        },
      ],
      rows: [],
      editorSchemas: [],
      searchTerm: '',
      totalRecords: 0,
      page: 1,
      perPage: 10,
      orderField: '',
      orderType: '',
    },
    datasetName: null,
  },
  getters: {
    getData: (state) => state.table,
    getEditorSchemas: (state) => state.table.editorSchemas,
    getDatasetName: (state) => state.datasetName,
  },
  actions: {
    async getSchemas({ commit }, { organizationId, tableName }) {
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/tables/schemas/${tableName}`
      )
      commit('SET_SCHEMA_DATA', destructJSONObject(response, 'table'))
    },
    async getEditorSchemas({ commit }, { organizationId }) {
      const response = await axiosIns.get(
        `${API_GATEWAY_URL}/api/v1/${organizationId}/biskql/tables/schemas`
      )
      commit('SET_EDITOR_SCHEMAS', destructJSONObject(response, 'tables'))
    },
  },
  mutations: {
    SET_SCHEMA_DATA(state, data) {
      state.table.rows = data.schema.columns
    },
    SET_EDITOR_SCHEMAS(state, schemas) {
      state.table.editorSchemas = schemas
      state.datasetName = schemas[0]?.dataset
    },
  },
}
